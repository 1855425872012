import React, { useEffect, useState, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  ArrowSmUpIcon,
  SearchIcon,
  SortDescendingIcon,
} from "@heroicons/react/outline";
import { FilterSelect } from "../../components/filter-select";
import Loading from "../../components/loading";
import API from "../../API";
import ProductItem from "./ProductItem";
import Filter from "./Filter";
import List from "./list";

export default function Store({userInfo}) {
  const [posts, setPosts] = useState([]);
  const didMount = useRef(false);
  const [loading, setLoading] = useState(false);
  const [noResult, setnoResult] = useState(false);
  const [skip, setskip] = useState(0);
  const [data, setData] = useState({
    skip: 0,
    sort: "createdAt",
    fetured: true,
    social_type: 'facebook',"search_type":"ad_description"
  });
  const [reset, setReset] = useState({});
  const { user, getAccessTokenSilently } = useAuth0();
  const [selected, setSelected] = useState();
  const [clear, setclear] = useState(false);
  const [Search, setSearch] = useState();
  const [Key, setKey] = useState(null);
  const [searchType, setSearchType] = useState();

  const box = document.getElementById("box");

  const handleFilter = (event) => {
    try {
      event.preventDefault();
      setPosts([]);
      setnoResult(false);
      let name = event.target.name;
      let value = event.target.value;
      if (name == 'sort') {
        setData({
          ...data, [name]: value, skip: 0
        });
      } else {
        setData({
          ...data, [name]: {
            from: event?.target?.from?.value,
            to: event?.target?.to?.value
          }, skip: 0
        });
      }
      setReset({ ...reset, [name]: value });
      setskip(0);

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selected?.name) {
      setPosts([]);
      setnoResult(false);
      setData((data) => ({
        ...data,
        [selected?.name]: selected?.value,
        skip: 0,
      }));
      setskip(0);
    }
  }, [selected]);

  const resetFilter = () => {
    try {
      setPosts([]);
      setclear(true);
      setskip(0);
      setData({ skip: 0, sort: "createdAt" });
      setReset({
        sort: "createdAt",
      });
      document.getElementById("search").value = "";
      setKey(Math.random() * 100 + "apply");
    } catch (error) {
      console.log(error);
    }
  };
  document.getElementById("logo")?.addEventListener("click", resetFilter);
  useEffect(() => {
    if (Search) {
      setPosts([]);
      setnoResult(false);
      setData((data) => ({ ...data, search: Search, search_type: searchType, skip: 0 }));
      setskip(0);
    }
  }, [Search, searchType]);

  const handleScroll = (event) => {
    const el = event.target.documentElement;
    if (
      el.scrollHeight - el.scrollTop < el.scrollHeight / 2 &&
      !loading &&
      !noResult
    ) {
      setLoading(true);
      setskip(skip + 1);
    }
  };
  window.addEventListener("scroll", handleScroll);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    resetFilter();
  }, []);

  useEffect(() => {
    if (skip !== 0) {
      setData((data) => ({ ...data, skip: skip }));
    }
  }, [skip]);

  useEffect(() => {
    if (didMount.current) {
      (async () => {
        try {
          setLoading(true);
          if (data.skip ==0) {
            setPosts([]);
          }
          // console.log(`filter=${JSON.stringify(data)}`)
          // setSearchParams(`filter=${JSON.stringify(data)}`);
          const res = await API.get(`store?data=${JSON.stringify(data)}`, {
            headers: {
              Authorization: `Bearer ${await getAccessTokenSilently()}`,
            },
          });

          setPosts((prev) => [...prev, ...res.data]);

          setLoading(false);
          if (res.data?.length === 0) {
            setnoResult(true);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    } else didMount.current = true;
  }, [data]);
  // useEffect(()=>{
  //   setPosts([])
  // },[posts])

  const handleSearch = (e, searchType) => {
    try {
      e.preventDefault();

      let search = e.target.search.value
        .replace(/[&/\\#,+()$~%.'":*?<>{}]/g, "")

      setSearchType(searchType)
      setSearch(search);
    } catch (error) {
      console.log(error);
    }
  };
  //   const handleSocial = (name) => {
  //     if (social !== name) {
  //       setPosts([])
  //       setSocial(name)
  //       setnoResult(false);
  //       setData((prev) => ({ ...prev, skip: 0}))
  //       setskip(0);
  //     }
  //   };
  const handleCalander = (start, end) => {
    try {
      setPosts([]);
      // setPageIndex(0)
      // setCountPages(0)
      setnoResult(false);
      setData((data) => ({
        ...data,
        calander: {
          start: start,
          end: end,
        },
        skip: 0,
      }));
      setskip(0);
    } catch (error) {
      console.log(error);
    }
  };
  const handleCalanderSeenBetween = (start, end) => {
    try {
      setPosts([]);
      // setPageIndex(0)
      // setCountPages(0)
      setnoResult(false);
      setData((data) => ({
        ...data,
        "seen_between": {
          start: start,
          end: end,
        },
        skip: 0,
      }));
      setskip(0);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <main
      className=" relative "
    
      style={{ height: "calc(100% - 112px)" }}
    >
      <div className="h-full w-full pt-3 border-b-[0.5px] border-[#E2E8F0] my-5 py-2 ">
        <Filter
          handleFilter={handleFilter}
          resetFilter={resetFilter}
          reset={reset}
          setSelected={setSelected}
          selected={selected}
          data={data}
          setclear={setclear}
          clear={clear}
          handleSearch={handleSearch}
          handleCalander={handleCalander}
          handleCalanderSeenBetween={handleCalanderSeenBetween}
					setData={setData}
          setKey={setKey}
          key={Key}
        />
        <div className="flex flex-col max-w-8xl mx-auto px-4 sm:px-6 lg:px-10">
        <List
                data={data}
                setReset={setReset}
                setData={setData}
                setPosts={setPosts}
                setSelected={setSelected}
                setskip={setskip}
                resetFilter={resetFilter}
                setKey={setKey}
              />
        </div>
       
      </div>

      <div className="h-full flex flex-col max-w-8xl mx-auto px-4 sm:px-6 lg:px-10">
        {posts?.length <= 0 && !loading ? (
          <div className="mt-3">
            {/* <List
              data={data}
              setReset={setReset}
              setData={setData}
              setPosts={setPosts}
              setSelected={setSelected}
              setskip={setskip}
              resetFilter={resetFilter}
            /> */}
            <div className="text-center h-full flex flex-col mt-3  justify-center items-center min-h-[250px]">
              <div className="mb-3 p-3 bg-slate-200 rounded-full">
                <SearchIcon className="w-6 text-gray-400" />
              </div>
              <h3 className="mt-2 text-sm font-medium text-gray-900  ">
                No result found
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Try different keyword
              </p>
            </div>
          </div>
        ) : (
          <>
            <div className="flex items-center justify-between mt-2 mb-2">
              {box.childNodes?.length === 0 && (
                <h2 className="text-2xl font-medium text-zinc-900">
                  {/* Items list products: */}
                </h2>
              )}
          
              {/* <div className="flex   items-center ">
                <div className="mr-2 flex items-center w-full text-gray-600">
                  <SortDescendingIcon className="w-5  mr-2 " />

                  <span className="lg:block hidden w-[60px]">Sort by:</span>
                </div>
                <FilterSelect
                  name="sort"
                  value={reset.sort}
                  options={
                    <>
                      <option className="text-gray-300" hidden>
                        Sort
                      </option>
                      <option value="createdAt">Post creation date</option>
                      <option value="updatedAt">Last seen</option>
                      <option value="likes">Likes</option>
                      <option value="comments">Comments</option>
                      <option value="shares">Shares</option>
                    </>
                  }
                  handleFilter={handleFilter}
                  htmlFor="sort"
                />
              </div> */}
            </div>
            <ul
              id="header"
              className="grid grid-cols-[repeat(auto-fit,minmax(285px,1fr))] gap-5"
            >
              {posts?.map((post, index) => (
                <li
                  key={index}
                  className="col-span-1  rounded-lg border-[1px] border-[#E2E8F0] w-full "
                  // style={{ boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)' }}
                >
                  {/* {JSON.stringify(posts)} */}
                  <ProductItem
                    product={post}
                    userInfo={userInfo}
                  // posts={posts}
                  // myList={myList}
                  // setMyList={setMyList}
                  />
                </li>
              ))}
            </ul>
          </>
        )}
        {loading  && <Loading />}
        {!posts?.length <= 0 && (
          <button
            type="button"
            className="fixed right-5 bottom-5 inline-flex items-center p-3 border border-gray-300 shadow-sm text-base font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={scrollToTop}
          >
            <ArrowSmUpIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        )}
      </div>
      
    </main>
  );
}
