import React, { useState, useEffect,Fragment } from "react";
import Select, { components } from "react-select";
import {
  CursorClickIcon,
  ViewGridAddIcon,
  SortDescendingIcon
} from "@heroicons/react/outline";
import { useLocation } from "react-router-dom";
import {
   FilterSelect,
  FilterMultiSelect,
} from "../../components/filter-select";
import OldCalander from "../../components/OldCalander";
import { Search }from "../../components/Searchbar";
import RangeInput from "./RangeInput";
import WishlistIcon from '../../assets/icons/wishlist.svg'
import NichesIcon from '../../assets/icons/niches.svg'
import CategoryIcon from '../../assets/icons/category.svg'
import PriceIcon from '../../assets/icons/price.svg'
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}


// const countries = [
//   { "label": "United Arab Emirates", "value": "AE" },
//   { "label": "Australia", "value": "AU" },
//   { "label": "Belgium", "value": "BE" },
//   { "label": "Brazil", "value": "BR" },
//   { "label": "Chile", "value": "CL" },
//   { "label": "China", "value": "CN" },
//   { "label": "Czech Republic", "value": "CZ" },
//   { "label": "Germany", "value": "DE" },
//   { "label": "Spain", "value": "ES" },
//   { "label": "France", "value": "FR" },
//   { "label": "Hungary", "value": "HU" },
//   { "label": "Indonesia", "value": "ID" },
//   { "label": "Israel", "value": "IL" },
//   { "label": "Italy", "value": "IT" },
//   { "label": "Japan", "value": "JP" },
//   { "label": "South Korea", "value": "KR" },
//   { "label": "Lithuania", "value": "LT" },
//   { "label": "Latvia", "value": "LV" },
//   { "label": "Nigeria", "value": "NG" },
//   { "label": "Poland", "value": "PL" },
//   { "label": "Russia", "value": "RU" },
//   { "label": "Saudi Arabia", "value": "SA" },
//   { "label": "Turkey", "value": "TR" },
//   { "label": "Ukraine", "value": "UA" },
//   { "label": "United Kingdom", "value": "UK" },
//   { "label": "United States", "value": "US" },
//   { "label": "Vietnam", "value": "VN" },
//   { "label": "South Africa", "value": "ZA" }
// ]
export default function Filter({
  handleFilter,
  resetFilter,
  selected,
  reset,
  setSelected,
  data,
  skip,
  setSkip,
  clear,
  setclear,
  handleSearch,
  handleCalander,
  handleCalanderSeenBetween,
  setData,
  userInfo,
  defaultSearch,
  Key,
  setKey
}) {

  const [closeMenu, setCloseMenu] = useState(null);
  const [asyncValue, setAsyncValue] = useState(undefined);
  const [asyncKey, setAsyncKey] = useState(0);
  const location = useLocation();
  const [search, setSearch] = useState(defaultSearch);
  const [Category, setCategory] = useState([
    { label: "Bags Shoes", value: "Bags Shoes" },
    { label: "Jewelry Watches", value: "Jewelry Watches" },
    { label: "Vehicles", value: "Vehicles" },
    { label: "Fashion", value: "Fashion" },
    { label: "Beauty", value: "Beauty" },
    { label: "Events", value: "Events" },
    { label: "Phone Electronics", value: "Phone Electronics" },
    { label: "Business Finance", value: "Business Finance" },
    { label: "Home Garden", value: "Home Garden" },
    { label: "Health Fitness", value: "Health Fitness" },
  ]);
  useEffect(() => {
    if (location.pathname === "/admin") {
      setCategory((prev) => [
        ...prev,
        { label: "uncategory", value: "uncategory" },
      ]);
    }
  }, []);
  function handaleSelect( select) {

    let defaultValue = [];
    if ( data[select.name]) {
      data[select.name].map((e) => {
        select.props.options.map((t) => {
          if (e == t.value) {
            return defaultValue.push(t);
          }
        });
      }); 
    }

    return (select.props.defaultValue = defaultValue);
}
  return (
    <div
   >


    <div className=" w-full max-w-8xl px-4 sm:px-6 lg:px-10 mx-auto font-medium text-[#6B7280]"
    >
 
      <div className="grid grid-cols-2 md:grid-cols-4  lg:grid-cols-5 xl:grid-cols-6 flex-wrap gap-x-2 gap-y-2 xl:gap-y-3.5 mx-auto   w-full py-8">
 <div    className={`col-span-1  w-full  lg:col-span-4 xl:col-span-3
             `}>

<Search
              className={"w-full "}
              userInfo={userInfo}
              setData={setData}
              search={search}
              setSearch={setSearch}
              data={data}
              skip={skip}
              setSkip={setSkip}

            />

 </div>
    
      <div className="flex relative">
            <div className="flex items-center m-auto absolute inset-y-2 text-[14px] left-[10px] text-[#6B7280] ">
              <SortDescendingIcon className="w-5 mr-2" />
            </div>
            <FilterSelect
              className="text-[#6B7280]"
              name="sort"
              value={reset.sort}
              options={
                <>
                 
                 <option value="createdAt">Post creation date</option>
                      <option value="updatedAt">Last seen</option>
                      <option value="likes">Likes</option>
                      <option value="comments">Comments</option>
                      <option value="shares">Shares</option>
                </>
              }
              handleFilter={handleFilter}
              // data={data}
              htmlFor="sort"
            />
          </div>
      {items.map((select, i) => {
          handaleSelect(select);
            return (
              <Fragment key={select.name}>
           
                  <div className="col-span-1 w-full relative text-[14px]">
                    <div className="flex items-center text-[#6B7280] z-20 m-auto absolute inset-y-2 left-[10px]  ">
                      {select.icon}
                    </div>
                    <Select
                       key={Key}
                      styles={{
                        clearIndicator: (styles, { data }) => ({
                          ...styles,
                          display: "none",
                        }),
                        dropdownIndicator: (styles, { data }) => ({
                          ...styles,
                          color: "#6B7280",
                        }),
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderRadius: "24px",
                          borderColor:'#E2E8F0',
                          boxShadow: "none",
                          padding: "0px 2px 0px 20px",
                        }),
                        option: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: "2px",
                        }),
                        valueContainer: (baseStyles, state) => ({
                          ...baseStyles,
                          display: "flex",
                          overflow: "auto",
                          flexWrap: "nowrap",
                          "::-webkit-scrollbar": {
                            height: "0px",
                          },
                        }),
                        multiValue: (baseStyles, state) => ({
                          ...baseStyles,
                          minWidth: "fit-content",
                          padding: "0px 4px 0px 0px",
                        }),
                        menu: (baseStyles, state) => ({
                          ...baseStyles,
                          zIndex: "999",
                          minWidth: "200px",
                          borderRadius: "8px",
                          padding: "6px",
                          color: "#6B7280",
                        }),
                        menuList: (baseStyles, state) => ({
                          ...baseStyles,
                          maxHeight: "240px",
                        }),
                        indicatorSeparator: (styles, { data }) => ({
                          ...styles,
                          display: "none",
                        }),
                        multiValueRemove: (base) => ({
                          ...base,
                          display: "none",
                        }),
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "rgba(255,237,213,255)",
                          primary: "rgba(255,108,61,255)",
                        },
                      })}
                      placeholder={
                        <div className="flex text-[#6B7280]">
                          <span className="line-clamp-1 break-all">
                            {select.label}
                          </span>
                        </div>
                      }
                
                      {...select.props}
                      menuIsOpen={closeMenu === i}
                      onMenuOpen={() => {
                        setCloseMenu(i);
                      }}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      onBlur={(e) => {
                        setCloseMenu(null);
                      }}
                      isSearchable
                      components={{
                        Menu: CustomButton,
                        Option: InputOption,
                      }}
                      apply={(e) => {
                        // setSelected((prev) => ({ ...prev, [select.name]: e }));
                        const newData = {
                          ...data,
                          [select.name]: Array.isArray(e)
                            ? e.map((el) => el.value)
                            : e
                            ? [e.value]
                            : undefined, skip:0
                        };
                        if (!e) {
                          const { [select.name]: _, ...nd } = newData;
                          setData(nd);
                        }
        
                        setData(newData);
                        setKey(Math.random() * 100 + "apply");
                        setCloseMenu(null);
                      }}
                    />
                  </div>
              
              </Fragment>
            );
          })}
        
        <RangeInput title={'Engagement '} name={'engagement'} handleFilter={handleFilter} reset={reset} icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 mr-2">
  <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 3.75V16.5L12 14.25 7.5 16.5V3.75m9 0H18A2.25 2.25 0 0 1 20.25 6v12A2.25 2.25 0 0 1 18 20.25H6A2.25 2.25 0 0 1 3.75 18V6A2.25 2.25 0 0 1 6 3.75h1.5m9 0h-9" />
</svg>
} />
        <RangeInput title={'Price '} name={'price'} handleFilter={handleFilter} reset={reset} icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 mr-2">
  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" />
</svg>
} />
        <OldCalander title={'Created between'} handleCalander={handleCalander} reset={reset} />
        <OldCalander title={'Seen between'} handleCalander={handleCalanderSeenBetween} reset={reset} />

      </div>

    </div>
    </div>
  );
}



const CustomButton = (props) => {

  return (
    <components.Menu {...props}>
      {props.children}
      <button
        onClick={() => {
          props.selectProps.apply(props.selectProps.value);
        }}
        className=" my-1 mx-auto border border-solid h-[30px] w-[180px] text-white border-[#ff6c3d] bg-[#ff6c3d] rounded-3xl transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-orange-400 focus:outline-none  px-2  flex items-center"
      >
        <span className="m-auto">Apply</span>
      </button>
    </components.Menu>
  );
};

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "rgba(255,237,213,255)";
  if (isActive) bg = "rgba(255,237,213,255)";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };
  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input
        type="checkbox"
        className="mr-2 rounded text-[rgba(255,108,61,255)]"
        checked={isSelected}
        onChange={() => {}}
      />
      <span className="line-clamp-1 break-all  ">{children}</span>
    </components.Option>
  );
};

const items = [
  {
    label: "Site Type",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-5 mr-2"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
        />
      </svg>
    ),
    type: "select",
    name: "platform",
    props: {
      options: [
        { label: "Shopify", value: "Shopify" },
        { label: "WordPress", value: "WordPress" },
        { label: "WooCommerce", value: "WooCommerce" },
        { label: "ClickFunnels", value: "ClickFunnels" },
        { label: "Webflow", value: "Webflow" },
        { label: "BigCommerce", value: "BigCommerce" },
        { label: "Wix", value: "Wix" },
        { label: "PrestaShop", value: "PrestaShop" },
      ],
      isMulti: true,
    },
  },
  {
    label: "Niches",
    icon:   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"         strokeWidth="1.5" stroke="currentColor" className="w-5 mr-2">
    <path strokeLinecap="round" strokeLinejoin="round" d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5" />
  </svg>
  ,
    type: "select",
    name: "niches",
    props: {
      options: [
        { "label": "Acne", "value": "Acne" },
        { "label": "Whitening", "value": "Whitening" },
        { "label": "Bodybuilding", "value": "Bodybuilding" },
        { "label": "Boxing", "value": "Boxing" },
        { "label": "Yoga", "value": "Yoga" },
        { "label": "Wolf", "value": "Wolf" },
        { "label": "Wedding", "value": "Wedding" },
        { "label": "Tatto", "value": "Tatto" },
        { "label": "Swimming", "value": "Swimming" },
        { "label": "Skiing", "value": "Skiing" },
        { "label": "Sewing", "value": "Sewing" },
        { "label": "Running", "value": "Running" },
        { "label": "Painting", "value": "Painting" },
        { "label": "Parenting", "value": "Parenting" },
        { "label": "Fishing", "value": "Fishing" },
        { "label": "Hiking", "value": "Hiking" },
        { "label": "Hunting", "value": "Hunting" },
        { "label": "Gaming", "value": "Gaming" },
        { "label": "Garden", "value": "Garden" },
        { "label": "Kitchen", "value": "Kitchen" },
        { "label": "Gadget", "value": "Gadget" },
        { "label": "Dogs", "value": "Dogs" },
        { "label": "Cats", "value": "Cats" },
        { "label": "Cooking", "value": "Cooking" },
        { "label": "Cats", "value": "Cats" },
        { "label": "cars", "value": "cars" },
        { "label": "Camping", "value": "Camping" },
        { "label": "Furniture", "value": "Furniture" },
        { "label": "Lighting", "value": "Lighting" }
      ],
      isMulti: false,
    },
  },
  {
    label: "Category",
    icon: <ViewGridAddIcon className="w-5 mr-2" />,
    type: "select",
    name: "category",
    props: {
      options: [
        { label: "Bags Shoes", value: "Bags Shoes" },
        { label: "Jewelry Watches", value: "Jewelry Watches" },
        { label: "Vehicles", value: "Vehicles" },
        { label: "Fashion", value: "Fashion" },
        { label: "Beauty", value: "Beauty" },
        { label: "Events", value: "Events" },
        { label: "Phone Electronics", value: "Phone Electronics" },
        { label: "Business Finance", value: "Business Finance" },
        { label: "Home Garden", value: "Home Garden" },
        { label: "Health Fitness", value: "Health Fitness" },
      ],
      isMulti: true,
    },
  },
  {
    label: "Social Media",
    icon:      <CursorClickIcon className="w-5 mr-2" />,

    type: "select",
    name: "social_media",
    props: {
      options: [
        { label: "Facebook", value: "facebook" },
        { label: "Instagram", value: "instagram" },
        { label: "Tiktok", value: "tiktok" },
        { label: "Pinterest", value: "pinterest" },
      ],
      isMulti: true,
    },
  },



 
];
