import React from "react";
import { DateObject } from "react-multi-date-picker";
export default function DatePanel({
  setValue,
  datePickerRef,
  value,
  title,
  data,
  setData,
  setAsyncValue,
  setAsyncKey,
  setKey
}) {
  const chooseDate = (i) => {
    try {
      if (data.filter_name) {
        const nd = { ...data, filter_name: null };
        setData(nd);
        setAsyncKey(Math.random() * 100);
   
        setAsyncValue(undefined);
      }
      setValue((prev) => ({
        ...prev,
        [title]: [new DateObject().add(i, "days"), new DateObject()],
      }));
      setData((prev) => ({
        ...prev,
        [title]: {
          start: new DateObject().add(i, "days"),
          end: new DateObject(),
        },
      }));
      datePickerRef.current.closeCalendar();
      setKey(Math.random() * 100 + "apply");

    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="grid ">
      <button
        onClick={(e) => {
          e.preventDefault();
          chooseDate(0);
        }}
        className="bg-white text-gray-700 hover:text-orange-500  focus:text-orange-400  hover:bg-gray-200 py-2 px-5  "
      >
        Today
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          chooseDate(-1);
        }}
        className="bg-white  text-gray-700 hover:text-orange-500  focus:text-orange-400  hover:bg-gray-200 py-2 px-5 "
      >
        Yesterday
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          chooseDate(-7);
        }}
        className="bg-white text-gray-700 hover:text-orange-500  focus:text-orange-400  hover:bg-gray-200 py-2 px-5 "
      >
        Last 7 days
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          chooseDate(-14);
        }}
        className="bg-white text-gray-700 hover:text-orange-500  focus:text-orange-400  hover:bg-gray-200 py-2 px-5 "
      >
        Last 14 days
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          chooseDate(-30);
        }}
        className="bg-white text-gray-700 hover:text-orange-500  focus:text-orange-400  hover:bg-gray-200 py-2 px-5 "
      >
        Last 30 days
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          chooseDate(-60);
        }}
        className="bg-white text-gray-700 hover:text-orange-500  focus:text-orange-400  hover:bg-gray-200 py-2 px-5 "
      >
        Last 60 days
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          chooseDate(-90);
        }}
        className="bg-white text-gray-700 hover:text-orange-500  focus:text-orange-400  hover:bg-gray-200 py-2 px-5 "
      >
        Last 90 days
      </button>
    </div>
  );
}
