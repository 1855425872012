import { useState, useEffect, Fragment } from "react";
import { MultiSelect } from "react-multi-select-component";
import { XIcon } from "@heroicons/react/outline";

export function FilterSelect({
  data,
  label,
  name,
  value,
  options,
  className,
  handleFilter,
  htmlFor,
  disabled,
}) {
  useEffect(() => {
 
    const options_array = Array.from(document.getElementById('large').options);
  let exist =   options_array.find(item=> item.value ===value)

    if (!exist ) {
      let e={
        target:{value :"last_seen"}
      }
      handleFilter(e);
      
    }

  }, [data]);

  return (
    <div className={`${className ?? ""} flex flex-col w-full`}>
      {label && (
        <label
          htmlFor={htmlFor}
          className="text-[14px] font-regular text-[#6B7280] flex items-center mb-1 "
        >
          {label}
        </label>
      )}
      <select
        name={name}
        disabled={disabled}
        onChange={(e) => {
          e.preventDefault();
          handleFilter(e);
        }}
        value={value ?? "Select..."}
        id="large"
        style={{ outline: "none", boxShadow: "none" }}
        className={`form-select block  pl-8 min-w-full rounded-3xl py-[7.5px] text-[14px] font-normal text-[#6B7280] border border-[#E2E8F0]  transition ease-in-out  focus:text-gray-700 focus:bg-white focus:border-orange-500  outline-none ${disabled && "bg-[#f2f2f2]"}`}
      >
        {options}
      </select>
    </div>
  );
}
export function FilterMultiSelect({
  label,
  name,
  options,
  className,
  setSelected,
  htmlFor,
  data,
  setData,
  hasSelectAll = true,
  singleSelect = false,
  deleteFilterLabel,
  setDeleteFilterLabel,
  // loading,
  // setData,
  // onClick
}) {
  const [val, setVal] = useState([]);
  const handle = (select) => {
    try {
      if (singleSelect === true) {
        setVal(select.length < 1 ? select : [select[select.length - 1]]);
        // setclear(false);
        setSelected({
          name: name,
          value: select.length < 1 ? [] : [select[select.length - 1].value],
        });
      } else {
        setVal(select);
        // setclear(false);
        let array = [];
        select.forEach((element) => {
          array.push(element.value);
        });
        setSelected({ name: name, value: array });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setVal([]);
    let newData = { ...data };
    delete newData[deleteFilterLabel];
    setData(newData);
  }, [deleteFilterLabel]);
  useEffect(() => {
    if (data?.[name]) {
      let array = [];
      data?.[name]?.forEach((item) => {
        let label = item;
        if (label.includes(" ")) {
          label = label
            .split(" ")
            .filter((e) => e !== " ")
            .join(" ");
        }
        if (label.includes("_")) {
          label = label
            .split("_")
            .filter((e) => e !== "_")
            .join(" ");
        }
        label = label[0].toUpperCase() + label.slice(1);
        array.push({ label, value: item });
      });
      setVal(array);
    }
  }, [data]);
  return (
    <div className={`${className ?? ""} flex flex-col w-full `}>
      <Fragment>
        {label && (
          <label
            htmlFor={htmlFor}
            className="text-sm font-regular text-gray-700 flex items-center mb-1"
          >
            {label}
          </label>
        )}
        <MultiSelect
          options={options}
          value={val}
          onChange={handle}
          hasSelectAll={hasSelectAll}
          // isLoading={loading}
          // className={'border  border-gray-400 rounded-md overflow-hidden hover:border hover:border-red-400 hover:rounded-md'}
          // disableSearch
          closeOnChangedValue
          isCreatable={false}
          ClearSelectedIcon={
            <XIcon
              className="w-4 ml-1 text-[black]"
              onClick={() => {
                let newData = { ...data };
                delete newData[name];
                setData(newData);
                setVal([]);
              }}
              color="#17A2B8"
            />
          }
        />
      </Fragment>
    </div>
  );
}
