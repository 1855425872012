import { useCallback } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

function Button2({ content, onClick, active, disabled }) {
  return (
    <button
      className={`
					flex flex-col cursor-pointer items-center  justify-center px-4  h-9 shadow-[0_4px_10px_rgba(0,0,0,0.03)] border border-[#ff6c3d] text-xs md:text-sm font-thin md:font-normal  rounded-3xl  hover:bg-[#ff6c3d] hover:text-white active:bg-[#ff6c3d] focus:text-[#fff] focus:bg-[#ff6c3d] active:text-[#fff] focus:border-[#ff6c3d] active:border-[#ff6c3d]
         disabled:border-[#7c9599] disabled:text-[#7c9599] disabled:bg-[#f9fafb] disabled:cursor-not-allowed ${active ? 'bg-[#ff6c3d] text-[#fff] ' :' bg-[#ffedd5] text-[#ff6c3d]'}
			  `}
      onClick={onClick}
      disabled={disabled}
    >
      {content}
    </button>
  );
}

function PaginationNav1({
  canPreviousPage,
  totalDocuments,
  pageIndex,
  pageItems,
  setSkip,
  scrollToTop,
  userInfo
}) {
  const pageCount = Math.round(Number(totalDocuments) / pageItems);
  const canNextPage = pageIndex < pageCount - 1;
  const renderPageLinks = useCallback(() => {
    if (pageCount === 0) return null;
    const visiblePageButtonCount = 7;
    let numberOfButtons =
      pageCount < visiblePageButtonCount ? pageCount : visiblePageButtonCount;
    const pageIndices = [pageIndex];
    numberOfButtons--;
    [...Array(numberOfButtons)].forEach((_item, itemIndex) => {
      const pageNumberBefore = pageIndices[0] - 1;
      const pageNumberAfter = pageIndices[pageIndices.length - 1] + 1;
      if (
        pageNumberBefore >= 0 &&
        (itemIndex < numberOfButtons / 2 || pageNumberAfter > pageCount - 1)
      ) {
        pageIndices.unshift(pageNumberBefore);
      } else {
        pageIndices.push(pageNumberAfter);
      }
    });
    return pageIndices.map((pageIndexToMap) => (
      <li key={pageIndexToMap}>
        <Button2
          content={pageIndexToMap + 1}
          onClick={() => {
            // gotoPage(pageIndexToMap)
            setSkip(pageIndexToMap);
            // alert('sdfsd')
            // setData(prev => ({ ...prev }))
            scrollToTop();
          }}
          active={pageIndex === pageIndexToMap}
        />
      </li>
    ));
  }, [pageCount, pageIndex]);
  return (
    <ul className="flex gap-2 ">
      <li>
        <Button2
          content={
            <div className="flex ml-1">
              <FaChevronLeft size="0.6rem" />
              <FaChevronLeft size="0.6rem" className="-translate-x-1/2" />
            </div>
          }
          onClick={() => {
            // setData(prev => ({ ...prev}))
            setSkip(0);
            // gotoPage(0)
          }}
          disabled={!canPreviousPage}
        />
      </li>
      <li>
        <Button2
          content={
            <div className="flex ml-1">
              <FaChevronLeft size="0.6rem" />
            </div>
          }
          onClick={() => {
            // setData(prev => ({ ...prev }))
            // gotoPage(pageIndex - 1)
            setSkip(pageIndex - 1);
          }}
          disabled={!canPreviousPage}
        />
      </li>
      {renderPageLinks()}
      <li>
        <Button2
          content={
            <div className="flex ml-1">
              <FaChevronRight size="0.6rem" />
            </div>
          }
          onClick={() => {
            // setData(prev => ({...prev ,skip: pageIndex + 1  }))
            setSkip(pageIndex + 1);
            // gotoPage(pageIndex + 1)
          }}
          disabled={!canNextPage}
        />
      </li>
      <li>
        <Button2
          content={
            <div className="flex ml-1">
              <FaChevronRight size="0.6rem" />
              <FaChevronRight size="0.6rem" className="-translate-x-1/2 " />
            </div>
          }
          onClick={() => {
            // setData(prev => ({...prev ,skip: pageCount - 1  }))
            // gotoPage(pageCount - 1)
            setSkip(pageIndex - 1);
          }}
          disabled={!canNextPage}
        />
      </li>
    </ul>
  );
}

export default PaginationNav1;
