import React, {useEffect} from 'react'

export default function Welcome() {

    useEffect(() => {
        try{
            window.ttq.track("startTrial")
            window.fbq('track', 'StartTrial');
        }catch(e){
            console.log(e)
        }
        window.location ='/'
    },[])
    return (
        <div></div>
    )
}
