import React from "react";
import { SearchIcon } from "@heroicons/react/solid";
import { Menu, Transition } from "@headlessui/react";
export default function RangeInput({ title,name,icon, handleFilter, }) {
  
    return (
        <form onSubmit={handleFilter} name={name} className="flex-col w-full">
            <label className="text-sm font-regular text-gray-700 flex items-center mb-1">
                {icon}
                <span className="break-all line-clamp-1 ml-2">{title}</span>
            </label>



            <div className="relative flex gap-1">
                <input
                    name={`from`}
                    id={`${name}_from`}
                    style={{ outline: 'unset', boxShadow: 'unset' }}
                    className=" px-2 h-[32px] w-full text-sm md:text-base font-normal  border border-solid border-[#c5cdcf] rounded  focus:text-gray-700 focus:bg-white focus:border-orange-500  focus:outline-none"
                    placeholder={`from`}
                    type="number"
                    required
                />
                <input
                    name={`to`}
                    id={`${name}_to`}
                    style={{ outline: 'unset', boxShadow: 'unset' }}
                    className=" px-2 h-[32px] w-full text-sm md:text-base font-normal  border border-solid border-[#c5cdcf] rounded  focus:text-gray-700 focus:bg-white focus:border-orange-500  focus:outline-none"
                    placeholder={`to`}
                    type="number"
                    required
                />

                <button
                    type="submit"
                    className=" border border-solid h-[32px] border-[#c5cdcf] rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-orange-500 focus:outline-none  px-2  flex items-center"
                >
                    <SearchIcon className="h-5 w-4 md:w-5 text-gray-400" aria-hidden="true" />
                </button>
            </div>

        </form>
    );
}
