import React, { useEffect } from "react";

export function Toast(props) {
  useEffect(() => {
    if (props.data) {
      const timeout = setTimeout(() => {
        props.setClose(null);
      }, 4000);
      return () => clearTimeout(timeout);
    }
  }, [props.data]);

  return (
    <div
      className={`${props.className ?? ""} ${
        props.data ? "slide-in visible" : "slide-out invisible opacity-0"
      } ${
        props.data.message.includes("failed") ||
        props.data.message.includes("Record doesn't exist") ||
        props.data.status !== 200
          ? "failure"
          : ""
      } success [transition:all_2s_ease] w-fit translate-x-[-50%] inline-flex bg-white rounded-md right-0 left-1/2 z-50 toast fixed bottom-[35px] p-3 border border-neutral-400 shadow-[5px_5px_10px_rgba(0,0,0,0.1)]`}
    >
      {props.data.message.includes("failed") ||
      props.data.message.includes("Record doesn't exist") ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 mr-2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 mr-2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      )}
      <div>{props.data.message}</div>
    </div>
  );
}
