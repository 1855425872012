import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import API from "../../API";
import Loading from "../../components/loading";
import PostItem from "../../components/postItem";
import { CSVLink } from "react-csv";

export default function Draft() {
  const [myDraftList, setMyDraftList] = useState([]);
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { user, getAccessTokenSilently } = useAuth0();
  const [playing, setPlaying] = useState(-1);

  useEffect(() => {
    (async () => {
      try {
        API.get(`draft?email=${user.email}`, {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        }).then((res) => {
          setMyDraftList([]);
          setPosts([]);
          // res.data.forEach((element) => {
          //   setMyDraftList((prev) => [...prev, element.post?._id]);
          //   setPosts((prev) => [...prev, element.post]);
          // });
          const sDraftList = res.data.map((data) => {
            return { _id: data.post?._id, isDeleted: data?.isDeleted, isExported: data?.isExported }
          })
          const fPosts = res.data.filter(val => val.isDeleted === false).map((data) => {
            return data.post
          })

          setMyDraftList(sDraftList);
          setPosts(fPosts);
          setIsLoading(false)
        });
      } catch (error) {
        console.log(error);
        setIsLoading(false)
      }
    })();
  }, [myDraftList]);
  const deleteAll = async () => {
    try {
      API.post(`/draft/${user.email}`, {
        draftList: posts
      }, {
        headers: {
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      }).then(() => {
        setMyDraftList([])
        setPosts([])
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <main
      className="mt-10" onMouseEnter={() => setPlaying(-1)}
    >
      <div className="max-w-8xl mx-auto pb-12 px-4 sm:px-6 lg:px-10 ">
        <h1 className="text-4xl font-semibold text-orange-500 pt-5">Draft</h1>
        <div className="flex h-full my-3 justify-between gap-4">
          <div>
          </div>
          <div className="space-x-5">
            {/* <button
              disabled={myDraftList.length === 0}
              // onClick={CsvExporter}
              className="bg-red-500 hover:bg-red-600 text-white  py-2 px-4 border border-red-500 rounded disabled:opacity-75"
            >
              Delete All
            </button> */}
            <CSVLink data={posts} filename={`${Date.now()}.csv`} onClick={deleteAll}>
              <button
                disabled={myDraftList.length === 0}
                // onClick={CsvExporter}
                className="bg-orange-500 hover:bg-orange-500 text-white  py-2 px-4 border border-orange-500 rounded disabled:opacity-75"
              >
                Export CSV
              </button>
            </CSVLink>

          </div>
        </div>
        {isLoading ? (
          <Loading />
        ) : myDraftList?.length !== 0 ? (
          <ul
            id="header"
            className="grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-5"
            onMouseEnter={() => setPlaying(-1)}
          >
            {posts?.map((post, index) => (
              <li
                key={post._id}
                className="col-span-1 bg-white rounded-lg shadow w-full"
              >
                <PostItem
                  post={post}
                  user={user}
                  myDraftList={myDraftList}
                  setMyDraftList={setMyDraftList}
                  posts={posts}
                  onMouseEnter={() => setPlaying(index)}
                      onMouseLeave={() => setPlaying(-1)}
                      playing={playing}
                      index={index}
                />
              </li>
            ))}
          </ul>
        ) : (
          <div className="text-center h-full flex flex-col justify-center items-center min-h-[250px]">
            <h3 className="mt-2 text-sm font-medium text-gray-900 ">
              No result found
            </h3>
          </div>
        )}
      </div>
    </main>
  );
}
