import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
export default function AliExpressAnalyticsChart({ data }) {
  const [chartData, setchartData] = useState({ datasets: [] });
  useEffect(() => {
    try {
      ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
      );
      let chart = [{ createdAt: null, orders: 0, wishlist: 0 }];
      let date = ""
      let orders = 0
      let wishlist = 0
      data?.analytic?.forEach(element => {
        if (date !== new Date(element.createdAt).toLocaleDateString("en-CA")) {
          if (orders > element.orders) {
            element.orders = orders
          }
          if (wishlist > element.wishlist) {
            element.wishlist = wishlist
          }
          chart.push(element)
          date = new Date(element.createdAt).toLocaleDateString("en-CA")
          orders = element.orders
          wishlist = element.wishlist
        }
      });
      setchartData({
        labels: chart.map((date) => {
          if (date.createdAt == null) {
            return "";
          } else {
            return new Date(date.createdAt).toLocaleDateString("en-CA");
          }
        }),
        datasets: [
          {
            label: "Orders",
            data: chart.map((orders) => orders?.orders),
            backgroundColor: ["#6AA84F"],
            borderColor: "#6AA84F",
            borderWidth: 3,
            // tension: 0.1
          },
          {
            label: "Wishlist",
            data: chart.map((wishlist) => wishlist.wishlist),
            backgroundColor: ["#50c7d1"],
            borderColor: "#50c7d1",
            borderWidth: 3,
            // tension: 0.1
          },
        ],
      });
    } catch (error) {
      console.log("error");
    }
  }, [data]);

  return (
    <>
      <Line
        // height={400}

        options={{
          responsive: true,
          maintainAspectRatio: false,
          // plugins: {
          //   legend: {
          //     position: 'top', // change this value to position the legend
          //     align: 'start',
          //     display: false
          //   },
          // },
          // scales: {
          //   yAxes: [
          //     {
          //       ticks: {
          //         beginAtZero: true
          //       }
          //     }
          //   ]
          // },
          // scales: {
          //   y: {
          //     type: 'logarithmic',
          //     // beginAtZero: true,
          //   },
          //   // x: {
          //   //   beginAtZero: true,

          //   // },

          // },
        }}
        data={chartData}
      />
    </>
  );
}
