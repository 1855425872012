import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../API';
import Loading from '../../components/loading';
import ImageSlider from './ImageSlider';
import CopyIcon from '../../assets/icons/copy.svg'
import Date from '../../assets/icons/date.svg'
import Daily from '../../assets/icons/daily.svg'
import OrderIcon from '../../assets/icons/d-order.svg'
import WishlistIcon from '../../assets/icons/d-wishlist.svg'
import TotalOrdersIcon from '../../assets/icons/d-total-orders.svg'
import TotalWishlistIcon from '../../assets/icons/d-total-wishlist.svg'
import VoteIcon from '../../assets/icons/d-vote.svg'
import PriceIcon from '../../assets/icons/d-price.svg'
import ReviewIcon from '../../assets/icons/d-review.svg'
import ShippingIcon from '../../assets/icons/d-shipping.svg'
import { fShortenNumber } from '../../utils/formatNumber';
import AliExpressAnalyticsChart from './analytics-chart';
import { lastseenDateCount, SeenDate } from '../../controllers/actions';
import PermitionModal from '../payment/PermissionModal';

const IconCard = ({ name, icon, count }) => {
    return (
        <div className="bg-white flex items-center justify-evenly px-2  py-4 rounded-md border-[1px] border-[#E2E8F0]"  >
            <img src={icon} alt='' />
            <div>
                <p className='text-[#383C3D] text-[28px] leading-8 font-[500] text-center'>{count}</p>
                <p className='text-[#828080] text-[16px] font-[400] text-center'>{name}</p>
            </div>
        </div>
    )
}
const ProductDetails = ({userInfo}) => {
    let { productId } = useParams();
    const { user, getAccessTokenSilently } = useAuth0();
    const [loading, setLoading] = useState(true)
    const [product, setProduct] = useState()
    const [viewModal, setViewModal] = useState(false);
    useEffect(() => {
        (async () => {
            try {
        if (userInfo) {
                const res = await API.get(`aliexpress/${productId}?user_id=${userInfo._id}`, {
                    headers: {
                        Authorization: `Bearer ${await getAccessTokenSilently()}`,
                    },
                },
                )

                setProduct(res.data[0]);
                setLoading(false)
            }
            } catch (error) {
                console.log(error);
                if (error?.response?.status === 429) {
                    // alert('sdddddd')
                    setViewModal(true)
                }
                //   setLoading(false)
            }
        })()
    }, [productId,userInfo]);


    if (loading && !viewModal) {
        return <div className='py-20'>
            <Loading />
        </div>
    }
    return (
        <div className='container  mx-auto max-w-8xl lg:px-20 pt-6'>
            {/* {JSON.stringify(product)} */}

            {!viewModal && (
                <>
                    <div className="grid grid-cols-12 gap-6">
                        <div className="lg:col-span-4 col-span-12 bg-white overflow-hidden rounded-md  border-[#E2E8F0] border-[1px]">
                            <ImageSlider images={product?.productImages} />
                        </div>
                        <div className="lg:col-span-8 col-span-12 flex flex-col justify-between">
                            <div className='flex items-center justify-between lg:flex-row flex-col  w-full  rounded-md py-5 px-5'>
                                <div className='lg:w-9/12 w-full'>
                                    <div className='flex items-center'>
                                        <p className='text-[#5E5E5E] text-[24px]  font-[700] line-clamp-1 break-all capitalize mix-blend-normal'>
                                            {product?.title}
                                        </p>
                                        {/* <img src={CopyIcon} className='cursor-pointer mr-4' style={{ width: 28, height: 28 }} onClick={() => navigator.clipboard.writeText(product?.title)} alt='' /> */}
                                    </div>
                                    <div className='flex items-center gap-4 mt-2'>
                                        <div className='flex items-center gap-2'>
                                            <img src={Date} alt='' />
                                            <p className='text-lg text-[#6B7677] '>Created: {lastseenDateCount(product?.createdAt)} </p>
                                        </div>
                                        <div className='flex items-center gap-2'>
                                            <img src={Daily} alt='' />
                                            <p className='text-lg text-[#6B7677] '>Last seen: {lastseenDateCount(product?.updatedAt)}       </p>
                                        </div>
                                    </div>
                                </div>
                                {/* <button className='text-lg w-48 h-16 bg-[#0F92A7] hover:bg-[#13a0b6] text-white rounded-lg'>Add to Store</button> */}
                            </div>
                            <div className=" grid grid-cols-4 rounded-md gap-3  p-6 ">
                                <IconCard name={'Daily Order'} count={fShortenNumber(product?.analytic[product?.analytic?.length - 1]?.orders < 0 ? 0 : product?.analytic[product?.analytic?.length - 1]?.orders)} icon={OrderIcon} />
                                <IconCard name={'Daily Wishlist'} count={fShortenNumber(product?.analytic[product?.analytic?.length - 1]?.wishlist)} icon={WishlistIcon} />
                                <IconCard name={'Votes'} count={product?.reviewsRatings.totalCount} icon={VoteIcon} />
                                <IconCard name={'Price'} count={product?.originalPrice.display} icon={PriceIcon} />
                                <IconCard name={'Total Orders'} count={fShortenNumber(product?.totalOrders)} icon={TotalOrdersIcon} />
                                <IconCard name={'Total Wishlist'} count={fShortenNumber(product?.wishlistCount)} icon={TotalWishlistIcon} />
                                <IconCard name={'Reviews'} count={product?.reviewsRatings.averageRating} icon={ReviewIcon} />
                                <IconCard name={'Shipping Cost'} count={`$${product?.shippingCost}`} icon={ShippingIcon} />
                            </div>
                            <div className="  w-full grid grid-cols-4 rounded-md gap-3  p-6">
                                <div className=" ">
                                    <a href={product?.productUrl} target={'_blank'}>
                                        <button className='w-full py-3 text-[#818181] hover:bg-gray-200 text-[16px] font-[400] text-center rounded-lg border '>Find on Aliexpress</button>
                                    </a>
                                </div>
                                <div className="">
                                    <a href={`https://www.amazon.com/s?k=${product?.title}`} target={'_blank'}>
                                        <button className='w-full py-3 text-[#818181] hover:bg-gray-200 text-[16px] font-[400] text-center rounded-lg border '>Find on Amazon</button>
                                    </a>
                                </div>
                                <div className="">
                                    <a href={`https://www.ebay.com/sch/i.html?_nkw=${product?.title}`} target={'_blank'}>
                                        <button className='w-full py-3 text-[#818181] hover:bg-gray-200 text-[16px] font-[400] text-center rounded-lg border '>Find on Ebay</button>
                                    </a>
                                </div>
                                <div className="">
                                    {/* <a href={`${product?.title}`} target={'_blank'}></a> */}
                                    <button className='w-full py-3 text-[#818181] hover:bg-gray-200 text-[16px] font-[400] text-center rounded-lg border '
                                    onClick={()=>{
                                        window.open(`https://www.google.com/search?q=site%3Amyshopify.com ${product?.title}`, "_blank");
                                    }}
                                    >Find On Shopify </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='h-[600px] my-10'>
                        {/* <div className='flex gap-4 my-5'>
                            <div className='bg-[#6AA84F] text-white rounded-full w-40 h-10 flex justify-center items-center'>
                                Orders
                            </div>
                            <div className='bg-orange-500 text-white rounded-full w-40 h-10 flex justify-center items-center'>
                                Wishlist
                            </div>
                        </div> */}
                        <AliExpressAnalyticsChart data={product} />
                    </div>
                </>
            )}
            <PermitionModal message="You have reached the maximum number of products query number" viewModal={viewModal} setViewModal={setViewModal} productModal />
        </div>
    );
}

export default ProductDetails;
