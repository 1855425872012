import React, {  useState } from "react";
import API from "../../API";
import { HeartIcon } from "@heroicons/react/outline";
import HeartIconSolid from "../heart-icon-solid";
import ToastSavedAd from "../toast-saved-ad";
// import { useLocation } from "react-router-dom";
import PopUp from "../Pop-up";
import { useAuth0 } from "@auth0/auth0-react";

export default function AddToFeturedBtn({ post}) {
  const [showToast, setShowToast] = useState(false);

  const [open, setOpen] = useState(false);
  const [Pick, setPick] = useState(post.is_fetured);
  const [deleteID, setDeleteID] = useState();
  const { user, getAccessTokenSilently } = useAuth0(); 
  const addtoFetured = async() => {
    try {
      API.patch(`posts/${post._id}?role=${JSON.stringify(user?.role)}`,{is_fetured:1}, {
        headers: {
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      }, 
        
      ).then(() => {
        setPick(1)
        alert("Successfully Added To Staff Picks")
      });
    } catch (error) {
      console.log(error);
    }
  };

  const Confirm = async(a) => {
    // console.log(deleteID)
    try {
      API.patch(`posts/${deleteID}?role=${JSON.stringify(user?.role)}` , {is_fetured:0},{
        headers: {
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      },).then(() => {
        setOpen(false);
        setPick(0)
      });
    } catch (error) {
      console.log(error);
    }
  };
  const deleteFromFetured = (id) => {
    try {
      setOpen(true);
      setDeleteID(id);
    } catch (error) {
      console.log(error);
    }
  };



  return (
    <>
      {
        // post.FavoriteId ? (
        // 	<button onClick={() => deleteFromFavorit(post.FavoriteId)}>
        // 		<HeartIconSolid className='w-6 text-gray-400 hover:text-gray-700' />
        // 	</button>
        // )
        // 	: (

        // isPostSaved
        Pick === 1
         ? (
          <>
            <button
              onClick={
               () => deleteFromFetured(post._id)
              }
            >
              <HeartIconSolid className="w-6 text-gray-400 hover:text-gray-700" />
            </button>
       
              <PopUp setOpen={setOpen} open={open} setConfirm={Confirm} />
            
          </>
        ) : (
          <button onClick={() => addtoFetured(post._id)}>
            <HeartIcon className="w-6 text-gray-400 hover:text-gray-700" />
          </button>
        )
        // )
      }
      {showToast && <ToastSavedAd setShowToast={setShowToast} />}
    </>
  );
}
