import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-multi-date-picker";
import DatePanel from "./DatePanel";
import "../controllers/orange.css";
// import InputIcon from "react-multi-date-picker/components/input_icon"
export default function Calander({ title, handleCalander, reset }) {
  const [value, setValue] = useState([]);
  useEffect(() => {
    setValue([]);
  }, [reset]);
  const datePickerRef = useRef();
  return (
    <div className="flex justify-center  w-full relative text-[14px]">
        <div className=" flex items-center m-auto absolute inset-y-2 left-[10px] text-[#6B7280] ">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-5  m-auto"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
          />
        </svg>
        {/* <span className="break-all line-clamp-1"> {title} </span> */}
      </div>
      <div className=" flex w-full gap-1">
        <DatePicker
          ref={datePickerRef}
          inputClass={`pl-[31px] h-[38px] w-[100%] font-normal  border border-solid border-[#E2E8F0]  transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-orange-500 focus:outline-none `}
          value={value}
          onChange={setValue}
          range
          numberOfMonths={2}
          format="DD MMM YYYY"
          calendarPosition="bottom-right"
          placeholder={title}
          fixMainPosition
          style={{
            width: "100%",
            outline: "none",
            boxShadow: "none",
            borderRadius: "24px",
            color:'#6B7280',
            fontSize:'14px'
          }}
          containerStyle={{
            width: "100%",
          }}
          className="orange"
          plugins={[
            <DatePanel
              position="right"
              value={value}
              handleCalander={handleCalander}
              setValue={setValue}
              datePickerRef={datePickerRef}
            />,
          ]}
        >
          <div className="w-full flex justify-end border-[1px] border-gray-200">
            <button
              onClick={(e) => {
                e.preventDefault();
                setValue([]);
                datePickerRef.current.closeCalendar();
              }}
              className="m-2 border border-gray-200 px-4 py-2 rounded text-gray-600 bg-white focus:bg-gray-200 hover:bg-gray-300 focus:outline-none "
            >
              Cancel
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                handleCalander(
                  value[0].toDate?.().toString(),
                  value[1].toDate?.().toString()
                );
                datePickerRef.current.closeCalendar();
              }}
              className=" m-2 mr-4 px-4 py-2 rounded text-white bg-orange-500 focus:bg-orange-500 hover:bg-orange-500 focus:outline-none "
            >
              Apply
            </button>
          </div>
        </DatePicker>
      </div>
    </div>
  );
}
