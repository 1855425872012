import React, { useState, useEffect, Fragment, useRef } from "react";
import API from "../API";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";
import number, { options } from "numeral";
import { useLocation } from "react-router-dom";
import {
  CursorClickIcon,
  FlagIcon,
  PaperClipIcon,
  TranslateIcon,
  ViewGridAddIcon,
  SortDescendingIcon,
} from "@heroicons/react/outline";
import { FilterSelect } from "./filter-select";

import MinReactions from "./min-reactions";
import Calander from "./Calander";
import Searchbar from "./Searchbar";
import Facebook from "../assets/icons/facebook.svg";
import Instagram from "../assets/icons/instagram.svg";
import Tiktok from "../assets/icons/tiktok.svg";
import Pinterest from "../assets/icons/pinterest.svg";
import List from "./list";
import { forEach, functionsIn, map } from "lodash";

// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ");
// }

export default function Filter({
  data,
  setData,
  setKey,
  setSkip,
  skip,
  init,

  totalDocuments,
  disable,
  handleSearch,
  social,
  refreshKey,
  reset,

  value,
  setValue,
  initialValue,

  userInfo,
}) {
  const defaultSearch = {
    value: "",
    label: "Ad Description",
    type: "ad_description",
  };
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [firstLoad, setfirstLoad] = useState(false);
  const isFilterChanged =
    Object.keys(data).length > 3 || data.sort !== "last_seen";
  const [showInput, setShowInput] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const location = useLocation();
  const [asyncValue, setAsyncValue] = useState(undefined);
  const [asyncKey, setAsyncKey] = useState(0);
  const [filters, setFilters] = useState([]);

  const [search, setSearch] = useState(defaultSearch);
   
  const [isSaveTriggered, setIsSaveTriggered] = useState(true);

  const [quickFilters, setQuickFilters] = useState({
    value: undefined,
    label: undefined,
  });

  async function getFilters() {
    try {
      setLoadingFilters(true);
      if (userInfo) {
        const res = await API.get(
          `filters?user_id=${userInfo._id}&social_type=${data.social_type}`
        );
        if (res.data) {
          const flrs = res.data?.filters.map((e, i) => {
            return {
              label: e.filter_name,
              value: e.filter_name,
              social_type: e.social_type,
              filters: {
                ...e.filters,
                search:
                  e.filters?.search?.length > 0
                    ? [encodeURIComponent(e.filters?.search[0])]
                    : undefined,
              },
            };
          });
          setFilters(flrs);
          setLoadingFilters(false);
        }
      }
    } catch (error) {
      setLoadingFilters(false);
      console.log(error, "filters error");
    }
  }

  const handleSocialType = (socialType) => {

        let type = socialType.split(".")
    if (type[0] !== data.social_type || type[1] !==data.ads_type) {
      if (skip > 0) {
        setSkip(0);
      }
      if (data.filter_name) {
        const d = { ...init, social_type: type[0] ,ads_type : type[1]};
        setData(d);
        setAsyncValue(undefined);
        setAsyncKey(Math.random() * 100);
        
      } else {
        const d = { ...data, social_type: type[0],ads_type : type[1] };
        setData(d);
      }
       setKey(Math.random() * 100 + "-social");
    }
  };

  function clearAsyncValue() {
    if (asyncValue) {
      setAsyncValue(undefined);
      setAsyncKey(Math.random() * 100);
    }
  }

  // useEffect(() => {
  //   if (typeof refreshKey === "string" && refreshKey?.includes("clear")) {
  //     setAsyncValue(undefined);
  //    setAsyncKey(Math.random() * 100);
  //     setData(init);
  //   }
  // }, [refreshKey]);

  useEffect(() => {
    if (quickFilters && (!data.ad_format || !data.search || !data.button)) {
      setQuickFilters(null);
    }
  }, [data.ad_format, data.search, data.button]);
  const resetSearchTerm = () => {
    setSearch(defaultSearch);
  };
  /*
	@param : fn => the function to convert
	@param : time => the time delay for debounce
	*/
  const debounce = (fn, delay) => {
    let timer = null;
    return (...args) => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => fn(...args), delay);
    };
  };

  const onSearch = () => {

    if (skip > 0) {
      setSkip(0);
    }
    if (search.value.length > 0) {
      setData((prev) => ({
        ...prev,
        search: [encodeURIComponent(escapeSpecialChars(search.value))],
        search_type: "ad_description",
      }));
    }
    // setKey(Math.random() * 100 + "apply");
  };
  const debouncedSearchHandler = debounce(onSearch, 1000);

  // listen to changes => debounced trigger search
  useEffect(() => {
    if (firstLoad) {
      debouncedSearchHandler();
    }

    setfirstLoad(true);
  }, [search]);

  // listen to changes => trigger search
  useEffect(() => {
    if (firstLoad) {
      onSearch();
    }
    setfirstLoad(true);
  }, [value]);

  const areFiltersChanged = Object.keys(value).some((e) => {
    if (value[e]) {
      if (value[e] === "last_seen") {
        return false;
      }
      if (Array.isArray(value[e]) && value[e].length === 0) {
        return false;
      }
      if (e === "reaction" && value[e].min.length === 0) {
        return false;
      }
      return true;
    }
    return false;
  });

  const values = {
    button: [
      { label: "SHOP NOW", value: "SHOP_NOW" },
      { label: "ORDER NOW", value: "ORDER_NOW" },
    ],
    ad_format: [{ label: "Video", value: "video" }],
  };

  const escapeSpecialChars = (value) => {
    return JSON.stringify(value).slice(1, -1);
  };

  let platforms = [
    { name: "Facebook", value: "facebook", src: Facebook },
    { name: "Facebook Library", value: "facebook_library", src: Facebook },
    { name: "Instagram", value: "instagram", src: Instagram },
    { name: "Pinterest", value: "pinterest.sponsored", src: Pinterest },
    { name: "Tiktok", value: "tiktok.sponsored", src: Tiktok },
    { name: "Tiktok Trending Ads", value: "tiktok.trending", src: Tiktok },
  ];

  if (userInfo && userInfo.roles.includes('admin')) {
    platforms.splice(4,0,{ name: "Pinterest Trending Video", value: "pinterest.trending", src: Pinterest })
  }

  const [closeMenu, setCloseMenu] = useState(null);

  function handaleSelect(data, key, select) {

      let defaultValue = [];
      if ( data[select.name]) {
        data[select.name].map((e) => {
          select.props.options.map((t) => {
            if (e == t.value) {
              return defaultValue.push(t);
            }
          });
        }); 
      }
      return (select.props.defaultValue = defaultValue);
  }

  return (
    <div className="border-b-[0.5px] border-[#E2E8F0] my-5 py-2">
      <div className="h-full w-full  ">
        {totalDocuments && location.pathname === "/" && (
          <div className="m-auto pt-4 w-full  md:flex-row  flex-col flex items-center justify-center text-[21px] font-bold  text-[#191C24] ">
            <h2 className="px-3 md:border-r-[1px]  ">🧑‍💻 Browse Ads </h2>
            <h2 className="whitespace-nowrap capitalize px-3 text-zinc-900 ">
              {data.social_type.replace("_", " ")} ADS:{" "}
              {number(totalDocuments).format("0,00")}
            </h2>
          </div>
        )}

        <AdsPlatform
        disable={disable}
        ads_type={data.ads_type}
          data={platforms}
          type={data.social_type}
          handleSocialType={handleSocialType}
        />

        <div className="  w-full max-w-8xl px-4 sm:px-6 lg:px-10 mx-auto font-medium text-[#6B7280]">
          <div className="flex gap-4 items-center  md:flex-row  flex-col ">
            <div className="flex  items-center text-black whitespace-nowrap text-[16px] font-semibold">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"
                />
              </svg>
              Quick queries :
            </div>
            <div className={`w-full flex gap-2 items-center`}>
              {data.social_type === "facebook" && (
                <button
                  className="py-2 px-3 border border-[#E2E8F0] rounded-3xl text-[14px]"
                  disabled={disable}
                  onClick={() => {
                    if (skip > 0) {
                      setSkip(0);
                    }
                    setData({
                      ...data,
                      fetured:true,
                      // search_type: "ad_description",
                      // ad_format: ["video"],
                      // button: ["SHOP_NOW", "ORDER_NOW"],
                      query: "Dropshipping",
                      // search: [
                      //   encodeURIComponent(
                      //     "20% | 30% | 40% | 50% | 60% | 70% off"
                      //   ),
                      // ],
                    });
                    setValue((prev) => ({ ...prev, ...values }));
                    setKey(Math.random() * 100 + "apply");
                  }}
                >
                  Dropshipping
                </button>
              )}

              {data.social_type === "facebook_library" && (
                <button
                  className="py-2 px-3 border border-[#E2E8F0] rounded-3xl text-[14px]"
                  disabled={disable}
                  onClick={() => {
                    if (skip > 0) {
                      setSkip(0);
                    }
                    setData((prev) => ({
                      ...prev,
                      search_type: "landing_page",
                      ad_format: ["video"],
                      button: ["SHOP_NOW", "ORDER_NOW"],
                      search: [],
                    }));
                    setValue((prev) => ({ ...prev, ...values }));
                    setKey(Math.random() * 100 + "apply");
                  }}
                >
                  COD
                </button>
              )}
              {data.social_type === "tiktok" && (
                <button
                  className="py-2 px-3 border border-[#E2E8F0] rounded-3xl text-[14px]"
                  disabled={disable}
                  onClick={() => {
                    if (skip > 0) {
                      setSkip(0);
                    }
                    setData({
                      ...data,
                      fetured:true,
                      // search_type: "ad_description",
                      // ad_format: ["video"],
                      // button: ["SHOP_NOW", "ORDER_NOW"],
                      query: "Dropshipping",
                      // search: [
                      //   encodeURIComponent(
                      //     "20% | 30% | 40% | 50% | 60% | 70% off"
                      //   ),
                      // ],
                    });
                    setValue((prev) => ({ ...prev, ...values }));
                    setKey(Math.random() * 100 + "apply");
                  }}
                >
                  Dropshipping
                </button>
              )}
              <button
                className={`py-2 px-3 border border-[#E2E8F0] rounded-3xl text-[14px] `}
                disabled={disable}
                onClick={() => {
                  if (skip > 0) {
                    setSkip(0);
                  }
                  setData((prev) => ({
                    ...prev,
                    fetured:false,
                    search_type: "landing_page",
                    ad_format: ["video"],
                    button: ["SHOP_NOW", "ORDER_NOW"],
                    query: "Ecommerce",
                    search: [encodeURIComponent("product")],
                  }));
                  setValue((prev) => ({ ...prev, ...values }));
                  setKey(Math.random() * 100 + "apply");
                }}
              >
                Ecommerce
              </button>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4  lg:grid-cols-5 xl:grid-cols-7 flex-wrap gap-x-2 gap-y-2 xl:gap-y-3.5 mx-auto max-w-8xl px-4 sm:px-6 lg:px-10 w-full py-5 ">
          <div
            className={`col-span-1  w-full ${
              (
                data.social_type === "facebook_library" 
              )
                ? "lg:col-span-5  xl:col-span-4"
   
                : "lg:col-span-4 xl:col-span-3"
            } `}
          >
            <Searchbar
              className={"w-full "}
              refreshKey={refreshKey}
              userInfo={userInfo}
              setData={setData}
              search={search}
              disable={disable}
              setSearch={setSearch}
              data={data}
              skip={skip}
              setSkip={setSkip}
              setKey={setKey}
            />
          </div>

          <div className="flex relative">
            <div className="flex items-center m-auto absolute inset-y-2 text-[14px] left-[10px] text-[#6B7280] ">
              <SortDescendingIcon className="w-5 mr-2" />
            </div>
            <FilterSelect
              className="text-[#6B7280]"
              name="sort"
              value={value["sort"]}
              disabled={  disable}
              options={
                <>
                  <option value="post_create">Post creation date</option>
                  <option value="last_seen">Last seen</option>
                  {data.social_type !== "facebook_library" && (
                    <>
                      {data.social_type === "tiktok" && (
                      <option value="video_views">Impression</option>)}
                      <option value="like">Likes</option>
                      <option value="comment">Comments</option>
                      <option value="share">Shares</option>
                    </>
                  )}
                  {data.social_type === "facebook_library" && (
                    <>
                      <option value="ads_count">Ads Count</option>
                    </>
                  )}
                </>
              }
              handleFilter={(e) => {
                setData((prev) => ({ ...prev, sort: e.target.value }));
                setValue((prev) => ({ ...prev, sort: e.target.value }));
                setKey(Math.random() * 100 + "apply");
              }}
              data={data}
              htmlFor="sort"
            />
          </div>
          {items.map((select, i) => {
            handaleSelect(data, refreshKey, select);
            const hasSocial = Array.isArray(select.social)
              ? select.social.some((s) => s === data.social_type)
              : true;

           
            return (
              <Fragment key={select.name}>
                {hasSocial && (
                  <div className="col-span-1 w-full relative text-[14px]">
                    <div className="flex items-center text-[#6B7280] z-20 m-auto absolute inset-y-2 left-[10px]  ">
                      {select.icon}
                    </div>
                    <Select
                      styles={{
                        clearIndicator: (styles, { data }) => ({
                          ...styles,
                          display: "none",
                        }),
                        dropdownIndicator: (styles, { data }) => ({
                          ...styles,
                          color: "#6B7280",
                        }),
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderRadius: "24px",
                          borderColor:'#E2E8F0',
                          boxShadow: "none",
                          padding: "0px 2px 0px 20px",
                        }),
                        option: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: "2px",
                        }),
                        valueContainer: (baseStyles, state) => ({
                          ...baseStyles,
                          display: "flex",
                          overflow: "auto",
                          flexWrap: "nowrap",
                          "::-webkit-scrollbar": {
                            height: "0px",
                          },
                        }),
                        multiValue: (baseStyles, state) => ({
                          ...baseStyles,
                          minWidth: "fit-content",
                          padding: "0px 4px 0px 0px",
                        }),
                        menu: (baseStyles, state) => ({
                          ...baseStyles,
                          zIndex: "999",
                          minWidth: "200px",
                          borderRadius: "8px",
                          padding: "6px",
                          color: "#6B7280",
                        }),
                        menuList: (baseStyles, state) => ({
                          ...baseStyles,
                          maxHeight: "240px",
                        }),
                        indicatorSeparator: (styles, { data }) => ({
                          ...styles,
                          display: "none",
                        }),
                        multiValueRemove: (base) => ({
                          ...base,
                          display: "none",
                        }),
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "rgba(255,237,213,255)",
                          primary: "rgba(255,108,61,255)",
                        },
                      })}
                      placeholder={
                        <div className="flex text-[#6B7280]">
                          <span className="line-clamp-1 break-all">
                            {select.label}
                          </span>
                        </div>
                      }
                      key={
                        // typeof refreshKey === "string" &&
                        // (refreshKey?.includes("clear") ||
                        //   refreshKey?.includes("value"))
                        //   ? refreshKey
                        //   : null
                          refreshKey
                      }
                      {...select.props}
                      isDisabled={disable}
                      menuIsOpen={closeMenu === i}
                      onMenuOpen={() => {
                        setCloseMenu(i);
                      }}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      onBlur={(e) => {
                        // setKey(Math.random() * 100 + "-value");
                        setCloseMenu(null);
                      }}
                      isSearchable
                      components={{
                        Menu: CustomButton,
                        Option: InputOption,
                      }}
                      apply={(e) => {
                        if (skip > 0) {
                          setSkip(0);
                        }
                        setValue((prev) => ({ ...prev, [select.name]: e }));
                        const newData = {
                          ...data,
                          [select.name]: Array.isArray(e)
                            ? e.map((el) => el.value)
                            : e
                            ? [e.value]
                            : undefined,
                        };
                        if (!e) {
                          const { [select.name]: _, ...nd } = newData;
                          setData(nd);
                        }
                        setData(newData);
                        if (data.filter_name) {
                          newData["filter_name"] = null;
                          clearAsyncValue();
                        }
                        setKey(Math.random() * 100 + "apply");
                        setCloseMenu(null);
                      }}
                    />
                  </div>
                )}
              </Fragment>
            );
          })}

          <div>
            <Calander
              setData={setData}
              setAsyncKey={setAsyncKey}
              setKey={setKey}
              setAsyncValue={setAsyncValue}
              refreshKey={refreshKey}
              disable={disable}
              data={data}
              title={"Created between"}
              initial={initialValue}
              value={value}
              setValue={setValue}
              userInfo={userInfo}
            />
          </div>
          <div>
            <Calander
              setData={setData}
              setAsyncKey={setAsyncKey}
              setKey={setKey}
              setAsyncValue={setAsyncValue}
              refreshKey={refreshKey}
              disable={disable}
              data={data}
              title={"Seen between"}
              initial={initialValue}
              value={value}
              setValue={setValue}
              userInfo={userInfo}
            />
          </div>
          {     data.social_type !== "facebook_library" && (
       <div>
       <MinReactions
         data={data}
         setData={setData}
         disable={disable}
         reset={reset}

         refreshKey={refreshKey}
         setKey={setKey}
         clearAsyncValue={clearAsyncValue}
         initial={initialValue}
         value={value}
         setValue={setValue}
         userInfo={userInfo}
       />
     </div>
          )

          }
   
          <div>
            <div className="relative col-span-1 text-[14px]">
              <div className="flex items-center z-20 m-auto absolute inset-y-2 left-[10px]  text-[#6B7280] ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-5 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z"
                  />
                </svg>
              </div>

              <Select
                key={asyncKey}
                placeholder={
                  <div className="flex  text-[#6B7280]">
                    <span className=" line-clamp-1 break-all">
                      Saved filters
                    </span>
                  </div>
                }
                styles={{
                  clearIndicator: (styles, { data }) => ({
                    ...styles,

                    display: "none",
                  }),
                  option: (baseStyles, state) => ({
                    ...baseStyles,
                    padding: "2px",
                  }),
                  indicatorSeparator: (styles, { data }) => ({
                    ...styles,
                    display: "none",
                  }),
                  menu: (baseStyles, state) => ({
                    ...baseStyles,
                    zIndex: "999",
                    minWidth: "200px",
                    borderRadius: "8px",
                    padding: "6px",
                    color: "#6B7280",
                  }),
                  menuList: (baseStyles, state) => ({
                    ...baseStyles,
                    maxHeight: "240px",
                  }),
                  dropdownIndicator: (styles, { data }) => ({
                    ...styles,
                    color: "#6B7280",
                  }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderRadius: "24px",
                    borderColor:'#E2E8F0',
                    boxShadow: "none",
                    padding: "0px 2px 0px 20px",
                    cursor:'pointer'
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "rgba(255,237,213,255)",
                    primary: "rgba(255,108,61,255)",
                  },
                })}
                options={filters}
                onMenuOpen={() => {
                  setCloseMenu("saved");
                  if (isSaveTriggered) {
                    getFilters();
                    setIsSaveTriggered(false);
                  }
                }}
                onBlur={() => setCloseMenu(null)}
                menuIsOpen={closeMenu === "saved"}
                isDisabled={ disable}
                apply={(e) => {
                  setAsyncValue([{ label: e.label, value: e.value }]);
                  const { _id: _, ...newFilters } = { ...e.filters };
                  const nd = { ...data, ...newFilters, filter_name: e.label };
                  setData(nd);
                  const filters = Object.keys(e.filters).reduce(
                    (acc, curr, ind, arr) => {
                      const value = e.filters[arr[ind]];
                      if (Array.isArray(value)) {
                        if (value.length === 0 && arr[ind] !== "reaction") {
                          acc[arr[ind]] = undefined;
                        } else {
                          if (arr[ind] === "search") {
                            setSearch((prev) => ({
                              ...prev,
                              value: decodeURIComponent(e.filters.search[0]),
                            }));
                          }
                          acc[arr[ind]] = value.map((e) => ({
                            label: e,
                            value: e,
                          }));
                        }
                      } else if (typeof value === "object") {
                        if (!value) {
                          acc[arr[ind]] = undefined;
                        } else {
                          if (
                            arr[ind] === "calander" ||
                            arr[ind] === "seen_between"
                          ) {
                            acc[arr[ind]] = [value.start, value.end];
                          }
                          if (typeof value === "string") {
                            acc[arr[ind]] = value;
                          }
                        }
                      }
                      if (arr[ind] === "reaction") {
                        if (e.filters[arr[ind]]) {
                          acc[arr[ind]] = {
                            name: e.filters[arr[ind]].name,
                            min: e.filters[arr[ind]].min,
                          };
                        } else {
                          acc[arr[ind]] = { name: "link", min: "" };
                        }
                      }
                      return acc;
                    },
                    {}
                  );
                  setValue((prev) => ({ ...prev, ...filters }));
                  setKey(Math.random() * 100 + "apply");
                  setCloseMenu(null);
                }}
                className=""
                isMulti={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{
                  Menu: CustomButton,
                  Option: InputOption,
                }}
              />
            </div>
          </div>
        </div>
 
      </div>
        <div className="w-full  max-w-8xl mx-auto px-4 sm:px-6 lg:px-10">
          <List
            data={data}
            setData={setData}
            setKey={setKey}
            setSkip={setSkip}
            skip={skip}
            init={init}
            disable={disable}
  
            setShowInput={setShowInput}
            showInput={showInput}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            clearAsyncValue={clearAsyncValue}
            initialValue={initialValue}
            value={value}
            setValue={setValue}
            userInfo={userInfo}
            setSearch={setSearch}
            resetSearchTerm={resetSearchTerm}
            setIsSaveTriggered={setIsSaveTriggered}
            setfirstLoad={setfirstLoad}
          />
        </div>

    </div>
  );
}

export function AdsPlatform({ data, handleSocialType, type,disable,ads_type }) {
  return (
    <div className=" border-b-[0.5px] border-[#E2E8F0] my-5">
      <div className=" max-w-8xl mx-auto  px-4 sm:px-6 lg:px-10 ">
        <div className=" flex gap-3 justify-center flex-wrap font-medium pt-2.5 text-[#6B7280]  ">
          {data.map((item) => {
            let selectd =   (type === item.value.split(".")[0] &&  ads_type === item.value.split(".")[1])
            return (
              <button
                key={item.value}
                disabled={disable}
                className={`flex items-center justify-center p-2  disabled:text-[#6B7280] disabled:border-[#6B7280] hover:pb-[6px] hover:border-b-[2.5px]  hover:text-[#4267B2] hover:border-[#4267B2] ${
                  selectd
                    ? "border-b-[2.5px] text-[#4267B2] border-[#4267B2] pb-[6px]"
                    : ""
                }`}
                onClick={() => handleSocialType(item.value)}
              >
                <img
                  className={`${!selectd ? "back-and-white" : ""}`}
                  src={item.src}
                  width={18}
                  alt="Facebook"
                />
                <span className={`ml-3`}>{item.name}</span>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
}

const CustomButton = (props) => {
  return (
    <components.Menu {...props}>
      {props.children}
      <button
        onClick={() => {
          props.selectProps.apply(props.selectProps.value);
        }}
        className=" my-1 mx-auto border border-solid h-[30px] w-[180px] text-white border-[#ff6c3d] bg-[#ff6c3d] rounded-3xl transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-orange-400 focus:outline-none  px-2  flex items-center"
      >
        <span className="m-auto">Apply</span>
      </button>
    </components.Menu>
  );
};

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "rgba(255,237,213,255)";
  if (isActive) bg = "rgba(255,237,213,255)";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input
        type="checkbox"
        className="mr-2 rounded text-[rgba(255,108,61,255)]"
        checked={isSelected}
        onChange={() => {}}
      />
      <span className="line-clamp-1 break-all  ">{children}</span>
    </components.Option>
  );
};

const countries = [
  { label: "United States", value: "United States" },
  { label: "South Africa", value: "South Africa" },
  { label: "Germany", value: "Germany" },
  { label: "Ecuador", value: "Ecuador" },
  { label: "Peru", value: "Peru" },
  { label: "Palestine", value: "Palestine" },
  { label: "France", value: "France" },
  { label: "China", value: "China" },
  { label: "Morocco", value: "Morocco" },
  { label: "Colombia", value: "Colombia" },
  { label: "Pakistan", value: "Pakistan" },
  { label: "Canada", value: "Canada" },
  { label: "United Kingdom", value: "United Kingdom" },
  { label: "Ukraine", value: "Ukraine" },
  { label: "Australia", value: "Australia" },
  { label: "Japan", value: "Japan" },
  { label: "Nigeria", value: "Nigeria" },
  { label: "Saudi Arabia", value: "Saudi Arabia" },
  { label: "United Arab Emirates", value: "United Arab Emirates" },
  { label: "Brazil", value: "Brazil" },
  { label: "Hungary", value: "Hungary" },
  { label: "India", value: "India" },
  { label: "Venezuela", value: "Venezuela" },
  { label: "Jordan", value: "Jordan" },
  { label: "South Korea", value: "South Korea" },
  { label: "Honduras", value: "Honduras" },
  { label: "Serbia", value: "Serbia" },
  { label: "Mexico", value: "Mexico" },
  { label: "Netherlands", value: "Netherlands" },
  { label: "Sweden", value: "Sweden" },
  { label: "Denmark", value: "Denmark" },
  { label: "Spain", value: "Spain" },
  { label: "Italy", value: "Italy" },
  { label: "Qatar", value: "Qatar" },
  { label: "Algeria", value: "Algeria" },
  { label: "Egypt", value: "Egypt" },
  { label: "Kuwait", value: "Kuwait" },
];

const languages = [
  { value: "en", label: "English" },
  { value: "es", label: "Spanish" },
  { value: "de", label: "German" },
  { value: "da", label: "Denmark" },
  { value: "ro", label: "Romanian" },
  { value: "et", label: "Estonian" },
  { value: "ar", label: "Arabic" },
  { value: "fr", label: "French" },
  { value: "ur", label: "Urdu" },
  { value: "nl", label: "Flemish" },
  { value: "ja", label: "Japanese" },
  { value: "pt", label: "Portuguese" },
  { value: "cs", label: "Czech" },
  { value: "tl", label: "Tagalog" },
];

const platforms = [
  { label: "Shopify", value: "Shopify" },
  { label: "WordPress", value: "WordPress" },
  { label: "WooCommerce", value: "WooCommerce" },
  { label: "ClickFunnels", value: "ClickFunnels" },
  { label: "Webflow", value: "Webflow" },
  { label: "BigCommerce", value: "BigCommerce" },
  { label: "Wix", value: "Wix" },
  { label: "PrestaShop", value: "PrestaShop" },
];

const actions = [
  { label: "SHOP NOW", value: "SHOP_NOW" },
  { label: "ORDER NOW", value: "ORDER_NOW" },
  { label: "LEARN MORE", value: "LEARN_MORE" },
  { label: "GET OFFER", value: "GET_OFFER" },
  { label: "BOOK TRAVEL", value: "BOOK_TRAVEL" },
  { label: "PLAY GAME", value: "PLAY_GAME" },
  { label: "LISTEN NOW", value: "LISTEN_NOW" },
  { label: "CONTACT US", value: "CONTACT_US" },
  { label: "SUBSCRIBE", value: "SUBSCRIBE" },
  { label: "DOWNLOAD", value: "DOWNLOAD" },
  { label: "APPLY NOW", value: "APPLY_NOW" },
  { label: "DONATE NOW", value: "DONATE_NOW" },
  { label: "WATCH MORE", value: "WATCH_MORE" },
];

const categories = [
  { label: "Bags Shoes", value: "Bags Shoes" },
  { label: "Jewelry Watches", value: "Jewelry Watches" },
  { label: "Vehicles", value: "Vehicles" },
  { label: "Fashion", value: "Fashion" },
  { label: "Beauty", value: "Beauty" },
  { label: "Events", value: "Events" },
  { label: "Phone Electronics", value: "Phone Electronics" },
  { label: "Business Finance", value: "Business Finance" },
  { label: "Home Garden", value: "Home Garden" },
  { label: "Health Fitness", value: "Health Fitness" },
];

const media = [
  { label: "Video", value: "video" },
  { label: "Image", value: "image" },
];

// const ads_options = [
//   { label: "Sponsored", value: "sponsored" },
//   { label: "Trending", value: "trending" },
// ];

// const dropshippingSelectOptions = [
// 	...actions,
// 	...media,
// ]

const items = [
  {
    label: "Site Type",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-5 mr-2"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
        />
      </svg>
    ),
    type: "select",
    name: "platform",
    props: {
      options: platforms,
      isMulti: true,
    },
  },
  {
    label: "Country",
    icon: <FlagIcon className="w-4 mr-2" />,
    type: "select",
    name: "country",
    props: {
      options: countries,
      isMulti: true,
    },
  },
  {
    label: "CTA",
    icon: <CursorClickIcon className="w-4 mr-2" />,

    type: "select",
    name: "button",
    props: {
      options: actions,
      isMulti: true,
    },
  },
  {
    label: "Media",
    icon: <PaperClipIcon className="w-4 mr-2" />,
    type: "select",
    name: "ad_format",
    props: {
      options: media,
    },
  },
  {
    label: "Language",
    icon: <TranslateIcon className="w-4 mr-2" />,
    type: "select",
    name: "t_lang",
    props: {
      options: languages,
      isMulti: true,
    },
  },
  // {
  //   label: "SponTrend Ads",
  //   icon: <PaperClipIcon className="w-4 mr-2" />,
  //   type: "select",
  //   name: "ads_type",
  //   social: ["tiktok", "pinterest"],
  //   props: {
  //     options: ads_options,
  //   },
  // },
  {
    label: "Category",
    icon: <ViewGridAddIcon className="w-4 mr-2" />,
    type: "select",
    name: "Category",
    props: {
      options: categories,
      isMulti: true,
    },
  },
];

function useRefArray(length) {
  const [refs, setRefs] = useState([]);

  useEffect(() => {
    setRefs(Array.from({ length }, () => React.createRef()));
  }, [length]);

  return refs;
}
// const initialQuickFilters = [
// 	{
// 		label: '20% | Shop now | Video',
// 		value: {
// 			search: [encodeURIComponent('20%')],
// 			ad_format: ['video'],
// 			button: ['SHOP_NOW']
// 		}
// 	},
// 	{
// 		label: '30% | Shop now | Video',
// 		value: {
// 			search: [encodeURIComponent('30%')],
// 			ad_format: ['video'],
// 			button: ['SHOP_NOW']
// 		}
// 	},
// 	{
// 		label: '40% | Shop now | Video',
// 		value: {
// 			search: [encodeURIComponent('40%')],
// 			ad_format: ['video'],
// 			button: ['SHOP_NOW']
// 		}
// 	},
// 	{
// 		label: '50% | Shop now | Video',
// 		value: {
// 			search: [encodeURIComponent('50%')],
// 			ad_format: ['video'],
// 			button: ['SHOP_NOW']
// 		}
// 	},
// 	{
// 		label: '60% | Shop now | Video',
// 		value: {
// 			search: [encodeURIComponent('60%')],
// 			ad_format: ['video'],
// 			button: ['SHOP_NOW']
// 		}
// 	},
// 	{
// 		label: '70% | Shop now | Video',
// 		value: {
// 			search: [encodeURIComponent('70%')],
// 			ad_format: ['video'],
// 			button: ['SHOP_NOW']
// 		}
// 	},
// ];
