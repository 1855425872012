import React, { Fragment, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { LogoutIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Search from "./Search";
import logohorizontal from "../assets/images/logos/adsteak_h_light.svg";
import logoSteak from "../assets/images/logos/adsteak_v_light.svg";
import API from "../API";
import number from "numeral";
import LoadingSpinner from "./loading-spinner";

export default function NavBar(props) {
  const { userInfo, handleSearch, queries, setUpdate ,plan} = props;
  const [showNav, setShowNav] = useState(false);
  const { user, logout, getAccessTokenSilently } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();
  const [userRequestCount, setUserRequestCount] = useState();
  const navigation = [
    { name: "Ads Search", href: "/", current: true },
    { name: "Product Research", href: "/products_research", current: false },
    { name: "Ads Staff Picks", href: "/ads_staff_picks", current: false },
    { name: "Favorites", href: "/favorites", current: false },
  ];

  const subscriptionEndDate =
    userInfo?.stripe_subscription &&
    new Date(
      userInfo.stripe_subscription?.current_period_end * 1000
    ).toLocaleDateString();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setShowNav(true);
      } else {
        setShowNav(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    saveAuthenticatedUser();
  }, []);

  // useEffect(() => {
  //   console.log('userInfo' + JSON.stringify(userInfo))
  //   console.log('user' + JSON.stringify(user))
  //   console.log('tuser' + typeof user)
  //   // console.log('user' + JSON.stringify(user['']))
  //   return () => {

  //   };
  // }, []);

  if (user?.role?.includes("admin")) {
    navigation.push({ name: "Aliexpress Products", href: "/aliexpress_products", current: false });
    // navigation.push({ name: "Admin", href: "/admin", current: false });
    navigation.push({ name: "Draft", href: "/draft", current: false });
  }

  // async function createStripePortalSession(){
  //   try {
  //     const res = await API.post(`stripe/create-session`, {
  //       data: {
  //         user_id: user._id,
  //         email: user.email
  //       }
  //     });
  //     setSessionUrl(res?.data?.session?.url)
  //     console.log(res, 'res')
  //   } catch (error) {
  //     console.log(error, 'session response error')
  //   }
  // }

  async function saveAuthenticatedUser() {
    try {
      // const res = await API.get(`user/${user._id}`, {
      //   headers: {
      //     Authorization: `Bearer ${await getAccessTokenSilently()}`,
      //   },
      // });
      setUserRequestCount(userInfo?.userRequestCount);
      // setUserInfo(res?.data?.user);
      // setSubscriptionStatus(res?.data?.subscriptionStatus)
      // console.log('userRequestCount' + JSON.stringify(res?.data?.userRequestCount))
    } catch (error) {
      console.log(error, "error in saving authenticated user");
    }
  }

  // const handleCancelSubscription = async () => {
  //   try {
  //     const res = await API.post(`stripe/cancel-subscription`, {
  //       userId: user._id,
  //     }, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //     });
  //     setSubscriptionStatus(res?.data?.subscription?.status)
  //     setViewModal(false)
  //   } catch (error) {
  //     // setError('Failed to cancel subscription');
  //   }
  // };
  // const handleResumeSubscription = async () => {
  //   try {
  //     const res = await API.post(`stripe/resume-subscription`, {
  //       userId: user._id,
  //     }, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //     });
  //     setSubscriptionStatus(res?.data?.subscription?.status)
  //   } catch (error) {
  //     console.log(error, 'failed to resume subscription')
  //     // setError('Failed to cancel subscription');
  //   }
  // };
  // function classNames(...classes) {
  //   return classes.filter(Boolean).join(' ')
  // }
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Disclosure
        as="nav"
        className={`bg-white  pt-1 w-full fixed top-0 z-50 ${showNav && "shadow-md"
          }`}
      // style={{position:showNav ? 'fixed' :'relative' }}
      // className="bg-gray-800"
      >
        {({ open }) => (
          <Fragment>
            <div
              className={`max-w-8xl px-4 sm:px-6 lg:px-10 pt-2 mx-auto md:gap-5 lg:text-center relative flex items-center justify-between h-14 gap-7 mb-5 `}
            >
              <div className="hidden [@media(max-width:964px)]:flex inset-y-0 left-2.5 items-center">
                {/* Mobile menu button*/}
                <Disclosure.Button
                  className="inline-flex items-center justify-center p-2 rounded-md text-gray-400
                hover:text-white hover:bg-[#2cbad0] focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                >
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex items-center gap-7">
                <Link
                  to="/"
                  id="logo"
                  className="flex-shrink-0 flex items-center"
                  onClick={scrollToTop}
                >
                  <img
                    className="block md:hidden h-12 w-auto"
                    src={logoSteak}
                    alt="Workflow"
                  />
                  <img
                    className="hidden md:block h-12 w-auto"
                    src={logohorizontal}
                    alt="Workflow"
                  />
                </Link>
              </div>
              <div className="hidden [@media(min-width:964px)]:flex lg:gap-5 md:gap-4 whitespace-nowrap">
                {navigation.map((link) => (
                  <Link
                    key={link.name}
                    to={link.href}
                    className={`${location.pathname === link.href && !link.soon
                        ? "bg-[rgba(255,237,213,255)] text-[rgba(255,108,61,255)] "
                        : "text-gray-900 hover:bg-[rgba(255,237,213,255)] "
                      } ${link.soon
                        ? "opacity-50 hover:no-underline hover:text-gray-900 hover:bg-white hover:border-none cursor-not-allowed"
                        : ""
                      } px-3 py-2 rounded-md text-md font-medium`}
                    onClick={() => {
                      scrollToTop();
                    }}
                  >
                    {link.name}
                    {link.soon && (
                      <span className="px-1.5 py-1 bg-[#f4f4f4] text-[#5a6c81] ml-2 rounded">
                        Soon
                      </span>
                    )}
                  </Link>
                ))}
              </div>
              {/* {location.pathname !== "/favorites" && (
              <div className="flex-1 px-2 lg:flex justify-center w-100  hidden lg:ml-6 lg:justify-end">
                <Search handleSearch={handleSearch} />
              </div>
              )} */}

              <div className="inset-y-0 right-2.5 flex items-center ">
                <MenuComponent
                  user={user}
                  setUpdate={setUpdate}
                  subscriptionEndDate={subscriptionEndDate}
                  userRequestCount={userRequestCount}
                  logout={logout}
                  navigate={navigate}
                  queries={queries}
                  userInfo={userInfo}
                  plan={plan}
                  getAccessTokenSilently={getAccessTokenSilently}
                />
              </div>
            </div>

            <Disclosure.Panel className="">
              <div className="px-2 pt-2 pb-3 space-y-1 flex flex-col">
                {navigation.map((link) => (
                  <Link
                    key={link.name}
                    to={link.href}
                    className={`${location.pathname === link.href
                        ? "bg-orange-500 text-white"
                        : "text-gray-800 hover:bg-orange-400 hover:text-white"
                      } px-3 py-2 rounded-md text-sm font-medium`}
                  >
                    {link.name}
                  </Link>
                ))}
                {/* {location.pathname !== "/favorites" && (
                  <Fragment>
                    {" "}
                    <div className="px-2 block md:hidden">
                      <Search handleSearch={handleSearch} />
                    </div>
                  </Fragment>
                )} */}
              </div>
            </Disclosure.Panel>
          </Fragment>
        )}
      </Disclosure>
      {/* <UnsubscribeModal viewModal={viewModal} setViewModal={setViewModal} onClick={handleCancelSubscription} /> */}
    </>
  );
}

function MenuComponent(props) {
  const {
    user,
    subscriptionEndDate,
    logout,
    navigate,
    userInfo,
    getAccessTokenSilently,
    queries,
    setUpdate,
    plan
  } = props;

  const [loading, setLoading] = useState(false);

  const [session, setSession] = useState({ url: null, once: true });

  async function stripeInit() {
    setLoading(true);
    try {
      const res = await API.post(
        `stripe/create-session`,
        {
          customer_id: userInfo?.customer_id,
        },
        {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        }
      );
      setSession({ url: res?.data?.session?.url });

      if (res.data) {
        setLoading(false);
      }
    } catch (error) {
      console.log(error, "session response error");
      setLoading(false);
    }
  }

  return (
    <Menu as="div" className="relative">
      <Menu.Button className="flex min-w-8 min-h-8 text-md rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
        <img
          className="h-8 w-8 rounded-full"
          src={user.picture}
          alt=""
          onClick={() => {
            if (session.once && userInfo?.customer_id) {
              stripeInit();
              setSession((prev) => ({ ...prev, once: false }));
            }
            setUpdate(Math.random() * 100);

          }}
        />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          static
          className="origin-top-right absolute divide-y divide-[0.5] divide-[#CCC]  right-0 mt-2 w-80  rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40"
        >
          <Menu.Item>
            <div className="flex flex-row gap-3 items-center px-4 py-3">
              <img className="h-8 w-8 rounded-full" src={user.picture} alt="" />
              <div className="flex flex-col items-start justify-start">
                <h4>{user.email}</h4>
                <p className="text-gray-400 text-sm">{user.name}</p>
              </div>
            </div>
          </Menu.Item>
          <Menu.Item>
            <div className="flex flex-row gap-3 items-center px-4 py-3">
              <p className=" text-md">Member:</p>
              <p className="font-bold">
                {plan?.member}
              </p>
            </div>
          </Menu.Item>
          {userInfo?.stripe_subscription && (
            <Fragment>
              <Menu.Item>
                <div className="flex flex-row gap-3 items-center px-4 py-3">
                  <p className=" text-md">
                    {userInfo.stripe_subscription?.status === "trialing"
                      ? "Trial ends on:"
                      : "Plan ends on:"}
                  </p>
                  <p className="font-bold">{subscriptionEndDate}</p>
                </div>
              </Menu.Item>           
              <Menu.Item>
                <div className="flex flex-row gap-3 items-center px-4 py-3">
                  <p className=" text-md">Subscription status:</p>
                  <p className="font-bold capitalize">
                    {userInfo.stripe_subscription?.cancel_at_period_end
                      ? "canceled"
                      : userInfo.stripe_subscription?.status}
                  </p>
                </div>
              </Menu.Item>
              <Menu.Item>
                <div className=" items-center px-4 py-3">

                  <div className="text-start">
                    Ad Analytics
                  </div>

                  <div className="flex items-center gap-x-3 whitespace-nowrap w-full">
                    <div className="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-neutral-700" role="progressbar" >
                      <div className="flex flex-col justify-center rounded-full overflow-hidden bg-[#ff6c3d] text-xs text-white text-center whitespace-nowrap transition duration-500 dark:bg-blue-500" style={{ width: (queries.analytics / plan.analytics * 100) + "%" }} ></div>
                    </div>
                    <div className="w-10 text-end mx-2">
                      <span className="text-sm text-gray-800 dark:text-white">{queries.analytics} / {plan.analytics}</span>
                    </div>
                  </div>

                </div>
              </Menu.Item>
              <Menu.Item>
                <div className=" items-center px-4 py-3">

                  <div className="text-start">
                    Products anylatics
                  </div>

                  <div className="flex items-center gap-x-3 whitespace-nowrap w-full">
                    <div className="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-neutral-700" role="progressbar" >
                      <div className="flex flex-col justify-center rounded-full overflow-hidden bg-[#ff6c3d] text-xs text-white text-center whitespace-nowrap transition duration-500 dark:bg-blue-500" style={{ width: (queries.products / plan.products * 100) + "%" }} ></div>
                    </div>
                    <div className="w-10 text-end mx-2">
                      <span className="text-sm text-gray-800 dark:text-white ">{queries.products} / {plan.products}</span>
                    </div>
                  </div>

                </div>
              </Menu.Item>
            </Fragment>
          )}
          {/* we hide these for now */}
          {/* <Menu.Item>
						<div className="flex flex-row gap-3 items-center px-4 py-3">
							<p className="text-md">Ads per query:</p>
							<p className="font-bold">{props.planQueries?.ads?.posts ?? 0} / {userInfo?.stripe_subscription?.plan?.nickname ==='basic' ? plans['basic'].posts : userInfo?.stripe_subscription?.plan?.nickname === 'pro' ? plans['pro'].posts  : userInfo?.stripe_subscription?.plan?.nickname === 'vip' ? plans['vip'].posts  : plans['free'].posts}</p>
						</div>
					</Menu.Item>
					<Menu.Item>
						<div className="flex flex-row gap-3 items-center px-4 py-3">
							<p className="text-md">Ad analytics viewed today:</p>
							<p className="font-bold">{props.planQueries?.ads?.analytics ?? 0} / {userInfo?.stripe_subscription?.plan?.nickname === 'basic' ? plans['basic'].analytics : userInfo?.stripe_subscription?.plan?.nickname === 'pro' ? plans['pro'].analytics : userInfo?.stripe_subscription?.plan?.nickname === 'vip' ? plans['pro'].analytics : plans['free'].analytics}</p>
						</div>
					</Menu.Item> */}
          {/* <Menu.Item>
						<div className="flex flex-row gap-3 items-center justify-between px-4 py-3">
							<p className="text-sm">Product analytics viewed today:</p>
							<p className="text-sm">{props.planQueries?.products?.analytics ?? 0} / {userInfo?.stripe_subscription?.plan?.nickname === 'starter' ? plans['basic'].products : userInfo?.stripe_subscription?.plan?.nickname === 'pro' ? plans['pro'].products : userInfo?.stripe_subscription?.plan?.nickname === 'vip' ? plans['vip'].products : plans['free'].products}</p>
						</div>
					</Menu.Item> */}
          <Menu.Item>
            {({ close }) => {
              // console.log(subscriptionStatus, 's stat')
              return (
                <>
                  {/* {subscriptionStatus === 'active' ? (
									<button 
										onClick={() => {
											setViewModal(true)
											close()
										}} 
										className={'w-full px-5'}>
										<p className="w-full py-3  bg-red-600 my-4 rounded-xl text-white">
											<span className="font-medium">
												Unsubscribe
											</span>
										</p>
									</button>
								) : subscriptionStatus === 'canceled' && (
										<button onClick={handleResumeSubscription} className={'w-full px-5'}>
											<p className="w-full py-3  bg-orange-500 my-4 rounded-xl text-white">
												<span className="font-medium">
													Resume
												</span>
											</p>
										</button>
									)
								}
								{user?.role[0] === 'trial' && (
									<button onClick={() => {
										close()
										navigate("/payment")
									}} className={'w-full px-5'}>
										<p className="w-full py-3  bg-orange-500 my-4 rounded-xl text-white">
											<span className="font-medium">
												Upgrade
											</span>
										</p>
									</button>
								)} */}
                  {/* {user?.role[0] === 'trial' && ( */}
                  {!userInfo?.stripe_subscription && (
                    <button
                      onClick={() => {
                        close();
                        navigate("/payment");
                      }}
                      className={"w-full px-5"}
                    >
                      <p className="w-full py-3  bg-orange-500 my-4 rounded-xl text-white">
                        <span className="font-medium">Upgrade</span>
                      </p>
                    </button>
                  )}
                </>
              );
            }}
          </Menu.Item>
          {userInfo?.stripe_subscription && (
            <Menu.Item>
              <a
                className="w-full px-5 block text-center"
                href={session.url}
                target="_blank"
              >
                <p className="w-full py-3  bg-[#ff6c3d] my-4 rounded-3xl text-white">
                  <span className="font-medium">
                    {loading ? <LoadingSpinner /> : "Manage subscription"}
                  </span>
                </p>
              </a>
            </Menu.Item>
          )}
          <Menu.Item>
            <button
              className="w-full flex items-center justify-center px-4 py-2 text-sm text-red-500 hover:bg-slate-100"
              onClick={async () => {
                logout({ logoutParams: { returnTo: window.location.origin } });
                // await loginWithRedirect()
                // navigate("/")
              }}
            >
              <LogoutIcon className="w-4 mr-2" />
              Sign out
            </button>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
