import React from "react";
import Button from "./button";
import { useAuth0 } from "@auth0/auth0-react";

export default function Header({ logo }) {
  const { loginWithRedirect } = useAuth0();
  const [open, setOpen] = React.useState(false);
  return (
    <header className="bg-white fixed w-full top-0 left-0 right-0 z-20">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 ">
        <div className="flex items-center justify-between border-b-2 border-gray-100 py-6 md:space-x-10">
          <a href="/">
            <img className="w-auto h-14" src={logo} alt="" />
          </a>
          {/* <div className="hidden space-x-10 md:flex">
						<a href="#" className="text-base font-medium text-gray-500 hover:text-gray-900">Features</a>
						<a href="#" className="text-base font-medium text-gray-500 hover:text-gray-900">Pricing</a>
						<a href="#" className="text-base font-medium text-gray-500 hover:text-gray-900">Faq's</a>
					</div> */}
          <div className="items-center justify-end hidden sm:flex">
            <button
              onClick={loginWithRedirect}
              className="mr-8 text-base font-medium text-gray-900 hover:text-orange-700"
            >
              Login
            </button>
            <Button
              loginWithRedirect={loginWithRedirect}
              children="Try now for Free"
            />
          </div>

          <div className="sm:hidden relative">
            <div className="inline-flex items-center justify-center cursor-pointer rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-500">
              {!open ? (
                // <Bars3Icon
                //   className="h-6 w-6"
                //   aria-hidden="true"
                //   onClick={() => setOpen((prev) => !prev)}
                // />
                <svg
                  onClick={() => setOpen((prev) => !prev)}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                  onClick={() => setOpen((prev) => !prev)}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}

              {/* <XMark className="h-6 w-6" aria-hidden="true" onClick={() => setOpen(prev => !prev)} />} */}
            </div>
            {open && (
              <div className="rounded-lg bg-white shadow-xl ring-2 ring-black ring-opacity-5 absolute top-12 right-0 w-96 px-5 py-8 flex flex-col items-center">
                <Button
                  loginWithRedirect={loginWithRedirect}
                  children="Try now for Free"
                  className="w-full"
                />
                <button
                  onClick={loginWithRedirect}
                  className="mt-4 text-base font-medium text-gray-900 hover:text-orange-700"
                >
                  Login
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}
