import React, { useEffect, useState } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Line } from "react-chartjs-2";

export default function AnalyticsChart({ Data }) {
  const [chartData, setchartData] = useState({ datasets: [] });
  useEffect(() => {
    try {
      ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
      );
      let chart = [{ created_at: null, like: 0, comment: 0, share: 0 }];
      let date = "";
      let like = 0;
      let comment = 0;
      let share = 0;
      if (Data?.analytic.length === 0) {
        setchartData({
          labels: ["", new Date(Data.created_at).toLocaleDateString("en-CA")],
          datasets: [
            {
              label: "Likes",
              data: [0, Data?.like],
              backgroundColor: ["#50c7d1"],
              borderColor: "#50c7d1",
              borderWidth: 3,
            },
            {
              label: "Comments",
              data: [0, Data?.comment],
              backgroundColor: ["#f1c232"],
              borderColor: "#f1c232",
              borderWidth: 3,
            },
            {
              label: "Shares",
              data: [0, Data?.share],
              backgroundColor: ["#6aa84f"],
              borderColor: "#6aa84f",
              borderWidth: 3,
            },
          ],
        });
      } else {
        Data?.analytic.forEach((element) => {
          if (
            date !== new Date(element.created_at).toLocaleDateString("en-CA")
          ) {
            if (like > element.like) {
              element.like = like;
            }
            if (comment > element.comment) {
              element.comment = comment;
            }
            if (share > element.share) {
              element.share = share;
            }

            chart.push(element);
            date = new Date(element.created_at).toLocaleDateString("en-CA");
            like = element.like;
            comment = element.comment;
            share = element.share;
          }
        });

        setchartData({
          labels: chart.map((date) => {
            if (date.created_at == null) {
              return "";
            } else {
              return new Date(date.created_at).toLocaleDateString("en-CA");
            }
          }),
          datasets: [
            {
              label: "Likes",
              data: chart.map((like) => like.like),
              backgroundColor: ["#50c7d1"],
              borderColor: "#50c7d1",
              borderWidth: 3,
            },
            {
              label: "Comments",
              data: chart.map((comment) => comment.comment),
              backgroundColor: ["#f1c232"],
              borderColor: "#f1c232",
              borderWidth: 3,
            },
            {
              label: "Shares",
              data: chart.map((share) => share.share),
              backgroundColor: ["#6aa84f"],
              borderColor: "#6aa84f",
              borderWidth: 3,
            },
          ],
        });
      }
    } catch (error) {
      console.log("error");
    }
  }, [Data]);

  return (
    <>
      <Line
        height={450}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          //   scales: {
          //     y: {
          // 	  type: 'logarithmic',
          //     },
          // 	x: {
          // 		beginAtZero: true,

          // 	  },

          //   },
        }}
        data={chartData}
      />
    </>
  );
}
