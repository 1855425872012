import React, { useState, Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ShoppingBagIcon, TrashIcon } from "@heroicons/react/outline";
import API from "../API";
import { PlusIcon } from "@heroicons/react/outline";
import ToastSavedAd from "./toast-saved-ad";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";

export default function AddToStoreBtn({
  post,
  product_model,
  store,
  onDeleteSuccess,
}) {
  const [showToast, setShowToast] = useState(false);
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { user, getAccessTokenSilently } = useAuth0();
  const [productId, setProductId] = useState("");
  let { productId: product_Id } = useParams();
  const addToStore = async (e) => {
    e.preventDefault();
    try {
      API.post(
        `store/addPostToProduct/${productId}?role=${JSON.stringify(
          user?.role
        )}`,
        {
          // API.post(`store/addPostToProduct/${productId}`, {
          product_id: post._id,
          product_model,
        },
        {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        }
      ).then(() => {
        // alert("Successfully Added To Store")
        setOpen(false);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const removeFromStore = async (e) => {
    e.preventDefault();
    try {
      API.post(
        `store/removeFromStore/${product_Id}?role=${JSON.stringify(
          user?.role
        )}`,
        {
          product_id: post._id,
        },
        {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        }
      ).then(() => {
        onDeleteSuccess();
        setOpen(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <button onClick={() => setOpen(true)}>
        {store ? (
          <TrashIcon className="w-6 text-gray-400 hover:text-gray-700" />
        ) : (
          <PlusIcon className="w-6 text-gray-400 hover:text-gray-700" />
        )}
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                  <form onSubmit={store ? removeFromStore : addToStore}>
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full  sm:mx-0 sm:h-10 sm:w-10">
                          <ShoppingBagIcon
                            className="h-6 w-6 text-gray-700"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-lg leading-6 font-medium text-gray-900"
                          >
                            {!store
                              ? " Add this post to store product"
                              : " Delete this post from the store"}
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              {!store
                                ? "Are you sure you want to add this post to the store?"
                                : "Are you sure you want to delete this post from the store?"}
                            </p>
                          </div>
                          <div className="mt-2">
                            {!store && (
                              <input
                                style={{ outline: "unset", boxShadow: "unset" }}
                                className=" px-2 h-[32px] w-full text-sm md:text-base font-normal  border border-solid border-[#c5cdcf] rounded  focus:text-gray-700 focus:bg-white focus:border-orange-500  focus:outline-none"
                                placeholder="product id"
                                required
                                value={productId}
                                onChange={(e) => setProductId(e.target.value)}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        type="submit"
                        className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  ${
                          !store
                            ? "bg-[rgb(22,163,74)] hover:opacity-80 focus:ring-green-500"
                            : " bg-[#ff0000] hover:opacity-80 "
                        } text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2  sm:ml-3 sm:w-auto sm:text-sm`}
                      >
                        {!store ? "Add" : "Delete"}
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-200 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 hover:opacity-80  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {showToast && <ToastSavedAd setShowToast={setShowToast} />}
    </>
  );
}
