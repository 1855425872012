import React, { useState } from "react";

export default function Description({ Description, id }) {
  const [showMore, setShowMore] = useState(false);
  const [showMoreID, setShowMoreID] = useState("");
  const ShowMoreText = (id) => {
    setShowMoreID(id);
    setShowMore(!showMore);
  };
  return (
    <div className="text-[#3C4344] min-h-[50px] text-[14px]">
      {Description ? (
        <p className="tooltip w-full px-4 mb-2 text-md  ">
          {showMore && showMoreID === id ? (
            Description
          ) : (
            <span className="line-clamp-2  break-all ">{Description}</span>
          )}
          <span className="tooltiptext">{Description}</span>
          {/* <button
            className="btn text-sm font-bold"
            onClick={() => ShowMoreText(id)}
          >
            {Description?.length > 40 &&
              (showMore && showMoreID === id
                ? ". Show less"
                : "Show more")}
          </button> */}
        </p>
      ) : (
        <p className="w-full px-4 mb-2 text-m text-gray-500">No description</p>
      )}
    </div>
  );
}
