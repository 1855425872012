import React, { useState, useEffect, Fragment } from "react";
import { useLocation } from "react-router-dom";
import {
  FilterMultiSelect,
} from "../../components/filter-select";
import {Search} from "../../components/Searchbar";
import RangeInput from "./RangeInput";
import ShippingIcon from '../../assets/icons/shipping.svg'
import DailyOrdersIcon from '../../assets/icons/daily-orders.svg'
import VotesIcon from '../../assets/icons/votes.svg'
import WishlistIcon from '../../assets/icons/wishlist.svg'
import NichesIcon from '../../assets/icons/niches.svg'
import TopCountiesIcon from '../../assets/icons/top-counties.svg'
import CategoryIcon from '../../assets/icons/category.svg'
import ShipFromIcon from '../../assets/icons/ship-from.svg'
import PriceIcon from '../../assets/icons/price.svg'
import TotalOrdersIcon from '../../assets/icons/total-orders.svg'
import lodash from 'lodash';

const countries = [
  { "label": "United Arab Emirates", "value": "AE" },
  { "label": "Australia", "value": "AU" },
  { "label": "Belgium", "value": "BE" },
  { "label": "Brazil", "value": "BR" },
  { "label": "Chile", "value": "CL" },
  { "label": "China", "value": "CN" },
  { "label": "Czech Republic", "value": "CZ" },
  { "label": "Germany", "value": "DE" },
  { "label": "Spain", "value": "ES" },
  { "label": "France", "value": "FR" },
  { "label": "Hungary", "value": "HU" },
  { "label": "Indonesia", "value": "ID" },
  { "label": "Israel", "value": "IL" },
  { "label": "Italy", "value": "IT" },
  { "label": "Japan", "value": "JP" },
  { "label": "South Korea", "value": "KR" },
  { "label": "Lithuania", "value": "LT" },
  { "label": "Latvia", "value": "LV" },
  { "label": "Nigeria", "value": "NG" },
  { "label": "Poland", "value": "PL" },
  { "label": "Russia", "value": "RU" },
  { "label": "Saudi Arabia", "value": "SA" },
  { "label": "Turkey", "value": "TR" },
  { "label": "Ukraine", "value": "UA" },
  { "label": "United Kingdom", "value": "UK" },
  { "label": "United States", "value": "US" },
  { "label": "Vietnam", "value": "VN" },
  { "label": "South Africa", "value": "ZA" }
]
export default function Filter({
  handleFilter,
  resetFilter,
  reset,
  setSelected,
  data,
  clear,
  setclear,
  handleSearch,
	init,
	setKey,
  setData,
  setSkip

}) {
	const isFilterChanged = lodash.isEqual(init, data);
  const location = useLocation();
  const [Category, setCategory] = useState([
    { label: "Bags Shoes", value: "Bags Shoes" },
    { label: "Jewelry Watches", value: "Jewelry Watches" },
    { label: "Vehicles", value: "Vehicles" },
    { label: "Fashion", value: "Fashion" },
    { label: "Beauty", value: "Beauty" },
    { label: "Events", value: "Events" },
    { label: "Phone Electronics", value: "Phone Electronics" },
    { label: "Business Finance", value: "Business Finance" },
    { label: "Home Garden", value: "Home Garden" },
    { label: "Health Fitness", value: "Health Fitness" },
  ]);
  useEffect(() => {
    if (location.pathname === "/admin") {
      setCategory((prev) => [
        ...prev,
        { label: "uncategory", value: "uncategory" },
      ]);
    }
  }, []);
  return (
    <div className=" max-w-6xl pt-3 pb-5 sm:px-6 mx-auto">
    {/* <Search setSearch={handleSearch} data={data} setData={setData}  setKey={setKey} /> */}
      {/* <div className="w-full my-4 h-[1px] bg-gray-200" /> */}

      {/* <div className="w-full my-2 h-[1px] bg-gray-200" /> */}
      <div className="mt-5 grid grid-cols-2 gap-x-4 gap-y-2  md:grid-cols-3 lg:grid-cols-5 ">
        <FilterMultiSelect
          label={
            <>
              <img src={ShippingIcon} alt='' />
              Shipping
            </>
          }
          options={countries}
          htmlFor="shipping"
          setSelected={setSelected}
          name="shipping"
          clear={clear}
          setclear={setclear}
          data={data}
					setData={setData}

        />
        <FilterMultiSelect
          label={
            <>
              <img src={ShipFromIcon} alt='' />
              Ships from
            </>
          }
          options={countries}
          setSelected={setSelected}
          htmlFor="Ships from"
          name="ships_from"
          clear={clear}
          setclear={setclear}
          data={data}
          setData={setData}
        />

        <FilterMultiSelect
          label={
            <>
              <img src={CategoryIcon} alt='' />
              category
            </>
          }
          options={[
            { label: "Food", value: "Food" },
            { label: "Accessories", value: "Accessories" },
            { label: "Electronics", value: "Electronics" },
            { label: "Home & Garden", value: "Home & Garden" },
            { label: "Sports", value: "Sports" },
            { label: "Health", value: "Health" },
            { label: "Outdoor Fun & Sports", value: "Outdoor Fun & Sports" },
            { label: "Security", value: "Security" },
            { label: "Vechlies", value: "Vechlies" },
            { label: "jewerly", value: "jewerly" },
            { label: "Lighting", value: "Lighting" },
            { label: "Beuty", value: "Beuty" },
            { label: "Fashion", value: "Fashion" },
            { label: "Bags & Shoes", value: "Bags & Shoes" },
            { label: "Phones", value: "Phones" },
          ]}
          htmlFor="category"
          setSelected={setSelected}
          name="category"
          clear={clear}
          setclear={setclear}
          data={data}
					setData={setData}
        />
        <FilterMultiSelect
          label={
            <>
              <img src={TopCountiesIcon} alt='' />
              Top Counties
            </>
          }
          options={countries}
          setSelected={setSelected}
          htmlFor="Top Counties"
          name="top_counties"
          clear={clear}
          setclear={setclear}
          data={data}
					setData={setData}
        />
        <FilterMultiSelect
          label={
            <>
              <img src={NichesIcon} alt='' />
              Niches
            </>
          }
          options={[
            { "label": "Acne", "value": "Acne" },
            { "label": "Whitening", "value": "Whitening" },
            { "label": "Bodybuilding", "value": "Bodybuilding" },
            { "label": "Boxing", "value": "Boxing" },
            { "label": "Yoga", "value": "Yoga" },
            { "label": "Wolf", "value": "Wolf" },
            { "label": "Wedding", "value": "Wedding" },
            { "label": "Tatto", "value": "Tatto" },
            { "label": "Swimming", "value": "Swimming" },
            { "label": "Skiing", "value": "Skiing" },
            { "label": "Sewing", "value": "Sewing" },
            { "label": "Running", "value": "Running" },
            { "label": "Painting", "value": "Painting" },
            { "label": "Parenting", "value": "Parenting" },
            { "label": "Fishing", "value": "Fishing" },
            { "label": "Hiking", "value": "Hiking" },
            { "label": "Hunting", "value": "Hunting" },
            { "label": "Gaming", "value": "Gaming" },
            { "label": "Garden", "value": "Garden" },
            { "label": "Kitchen", "value": "Kitchen" },
            { "label": "Gadget", "value": "Gadget" },
            { "label": "Dogs", "value": "Dogs" },
            { "label": "Cats", "value": "Cats" },
            { "label": "Cooking", "value": "Cooking" },
            { "label": "Cats", "value": "Cats" },
            { "label": "cars", "value": "cars" },
            { "label": "Camping", "value": "Camping" },
            { "label": "Furniture", "value": "Furniture" },
            { "label": "Lighting", "value": "Lighting" }
          ]}

          setSelected={setSelected}
          htmlFor="Niches"
          name="niches"
          clear={clear}
          setclear={setclear}
          data={data}
          // hasSelectAll={true}
          singleSelect={true}
					setData={setData}
        />


        <RangeInput title={'Price'} name={'price'} handleFilter={handleFilter} icon={<img src={PriceIcon} alt='' />} />
        <RangeInput title={'Total Orders Oumber'} name={'total_orders_number'} handleFilter={handleFilter} icon={<img src={TotalOrdersIcon} alt='' />} />
        <RangeInput title={'Daily Orders'} name={'daily_orders'} handleFilter={handleFilter} icon={<img src={DailyOrdersIcon} alt='' />} />
        <RangeInput title={'Votes'} name={'votes'} handleFilter={handleFilter} icon={<img src={VotesIcon} alt='' />} />
        <RangeInput title={'Wishlist'} name={'wishlist'} handleFilter={handleFilter} icon={<img src={WishlistIcon} alt='' />} />
				
				{
          !isFilterChanged && (
            <Fragment>
              <button 
                className="w-full self-end py-2 items-end shadow-sm text-sm font-medium rounded-md bg-orange-500 hover:bg-[#118a9c] text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                onClick={() => setKey(Math.random()*100)}
              >
                Apply
              </button>
              <button 
                className="w-full self-end py-2 items-end shadow-sm text-sm font-medium rounded-md bg-orange-500 hover:bg-[#118a9c] text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                onClick={() => {}}
              >
                Save filter
              </button>
            </Fragment>
          )
        }
      </div>

    </div>
  );
}
