import React, { Fragment, useEffect, useState } from "react";
import { SearchIcon } from "@heroicons/react/solid";
import { Menu, Transition } from "@headlessui/react";
export default function RangeInput({ title,name,icon,reset, handleFilter, }) {

    const [show, setShow] = React.useState(false);
    return (
        <>
        
        
        <div className=" text-[#6B7280]  w-full h-full text-[14px] ">
      <Menu as="div" className={"relative"}>
        <div>
          <Menu.Button      className="w-full h-full relative ">
            <div className=" ">
              <div className="  absolute inset-y-2 left-[10px] ">
             {icon}
              </div>
              <div  className={`py-[7px] text-left bg-white  border rounded-3xl border-[#E2E8F0] focus:ring-orange-500 bg-gray-100 focus:border-orange-500 `}  >
                <div className="ml-[33px] mr-2">
                  <span className=" line-clamp-1 break-all">
                    {/* {data?.reaction?.min > 0 ? (
                      <>{data.reaction.min + " " + `${data.reaction.name}s`}</>
                    ) : ( */}
                      {title}  
                    {/* )} */}
                  </span>
                </div>
              </div>
            </div>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items as={Fragment} className="absolute ">
          <form onSubmit={handleFilter} name={name}    style={{ zIndex: "80" }}
              className="right-0 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg p-2 min-w-[200px]">
     
                   <div className="flex flex-col gap-2 ">
                   <input
                       name={`from`}
                       id={`${name}_from`}
                       style={{ outline: 'unset', boxShadow: 'unset' }}
                       className=" px-2 h-[38px] w-full text-sm md:text-base font-normal border border-solid border-[#c5cdcf] rounded  focus:text-gray-700 focus:bg-white focus:border-orange-500  focus:outline-none"
                       placeholder={`from`}
                       type="number"
                       required
                   />
                   <input
                       name={`to`}
                       id={`${name}_to`}
                       style={{ outline: 'unset', boxShadow: 'unset' }}
                       className=" px-2 h-[38px] w-full text-sm md:text-base font-normal border border-solid border-[#c5cdcf] rounded  focus:text-gray-700 focus:bg-white focus:border-orange-500  focus:outline-none"
                       placeholder={`to`}
                       type="number"
                       required
                   />
   
   <Menu.Item>
                  <button
                    type="submit"
                    onClick={()=>{
                      
                    }}
                    className=" border border-solid h-[30px] text-white border-[#ff6c3d] bg-[#ff6c3d] rounded-3xl transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-orange-400 focus:outline-none  px-2  flex items-center"
                  >
                    <span className="m-auto">Apply</span>
                  </button>
                </Menu.Item>
               </div> 
    
        
        </form>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
      
        </>
    );
}
