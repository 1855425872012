import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import API from '../../API';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

const CheckoutForm = ({ plan }) => {
    const { user } = useAuth0()
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate()
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (elements == null) {
            return;
        }
        setLoading(true)
        const cardElement = elements.getElement(CardElement);

        // alert('sdfsd')
        try {
            const { paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });
            if (error) {
                setError(error.message);
            } else {
                // console.log(paymentMethod.paymentMethod)
                // const { id } = paymentMethod;
                try {
                    const res = await API.post(`stripe/subscribe`, {
                        paymentMethod: paymentMethod?.id,
                        name: user.name,
                        email: user.email,
                        priceId: plan === 'starter' ? 7700 : plan === 'vip' ? 15500 : plan === 'pro' && 26300,
                        userId: user._id,
                        plan
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                    if (res.data.success) {
                        navigate("/", { replace: true })
                        window.location.reload(true)
                        setSuccess(true);
                    } else {
                        setError('Subscription failed');
                    }
                } catch (error) {
                    setError('Subscription failed');
                }
            }
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    };


    return (
        <>
            {!success && (
                <form onSubmit={handleSubmit}>
                    <CardElement />
                    <button disabled={loading} type='submit' className={'w-full mt-4'}>
                        <p className={`w-full py-4 ${loading ? "bg-gray-300" : "bg-orange-500"} mt-8 rounded-xl text-white`}>
                            <span className="font-medium">
                                Subscribe {plan === 'starter' ? '77$' : plan === 'vip' ? '155$' : plan === 'pro' && '263$'}
                            </span>
                        </p>
                    </button>
                </form>
            )}
            {/* {success && (
                <>
                    <div>Subscription successful!</div>
                    <button onClick={handleCancelSubscription}>Cancel Subscription</button>
                </>
            )} */}
            {error && <div className='text-red-600 mt-4'>{error}</div>}
        </>
    );
};
export default CheckoutForm;