import React, { useId, useRef } from "react";
import Slider from "react-slick";
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'

function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <IoIosArrowForward
            className='absolute right-[-20px] top-0 bottom-0 my-auto cursor-pointer'
            size={20}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <IoIosArrowBack
            className='absolute left-[-20px] top-0 bottom-0 my-auto cursor-pointer'
            size={20}
            onClick={onClick}
        />
    );
}


export default function ImageSlider({ images }) {
    const slider_ref = useRef()
    const slider_ref_2 = useRef()
    const settings_1 = {
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    const settings_2 = {
        infinite: true,
        slidesToShow: images.length >= 3 ? 3 : images.length,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };
    return (
        <div>
            <Slider {...settings_1} ref={slider_ref}  >
                {/* <img  src={[images[0]]} /> */}
                {images.map((src, index) => {
                    return (
                        <div key={src} className={'p-2'} >
                            <img style={{ height: 350, width: '100%', objectFit: 'contain' }} src={src} />
                        </div>
                    )
                })}
            </Slider>
            <div className="pb-2 px-10">
                <Slider {...settings_2} ref={slider_ref_2}>
                    {/* <img  src={[images[0]]} /> */}
                    {/* <div>{JSON.stringify(images)}</div> */}
                    {images.map((src, index) => {
                        return (
                            <div key={`slider_${src}`} className={'px-3 cursor-pointer'} onClick={() => { slider_ref.current.slickGoTo(index) }}>
                                <img style={{ height: 84, width: 93, objectFit: 'contain' }} src={src} />
                            </div>
                        )
                    })}
                </Slider>
            </div>
        </div>
    );
}
