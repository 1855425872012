import React, { useState } from "react";
import { XIcon } from '@heroicons/react/solid'
const lang = [
  { value: "en", label: "English" },
  { value: "es", label: "Spanish" },
  { value: "de", label: "German" },
  { value: "da", label: "Denmark" },
  { value: "ro", label: "Romanian" },
  { value: "et", label: "Estonian" },
  { value: "ar", label: "Arabic" },
  { value: "fr", label: "Franch" },
  { value: "ur", label: "Urdu" },
  { value: "nl", label: "Flemish" },
  { value: "ja", label: "Japanese" },
  { value: "pt", label: "Portuguese" },
  { value: "cs", label: "Czech" },
  { value: "tl", label: "Tagalog" },
];
const FilerItem = ({ value, deleteDropdown, title }) => {
  return value.map((element, index) => {
    return (
      <div
        key={index}
       className="flex gap-1 items-center whitespace-nowrap ] rounded-full bg-[rgba(255,237,213,255)] py-2 mr-2 px-3"
      >
        <p >
          {title === "t_lang"
            ? lang.map((el) => {
              if (el.value === element) {
                return el.label;
              }
            })
            : element.split(" ").join("_")}
        </p>

        {/* <button
          title={title}
          // className="px-1 "
          onClick={deleteDropdown}
          name={element}
        >
        </button> */}
        <XIcon className="w-4 text-[rgba(255,108,61,255)]" onClick={() => deleteDropdown(title, element)} color='text-orange-500' />
      </div>
    );
  });
};

export default function List({
  data,
  setReset,
  setData,
  setPosts,
  setSelected,
  setskip,
  resetFilter,
  setKey
}) {
  const [newfilter, setnewfilter] = useState(data);

  const deleteFilter = (name) => {
    setData((data) => ({ ...data, [name]: null,}));

    setKey(Math.random() * 100 );
    // setPosts([]);
    // setskip(1);
    // setnewfilter({ ...newfilter });
    // setData({ ...newfilter });

    // if (name === "reaction") {
    //   document.getElementById("min").value = "";
    //   document.getElementById("reaction").value = "like";
    // } else if (name === "search") {
    //   document.getElementById("search").value = "";
    // }
    // if (name === "engagement") {
    //   document.getElementById("engagement_from").value = "";
    //   document.getElementById("engagement_to").value = "";
    // } 
    // if (name === "price") {
    //   document.getElementById("price_from").value = "";
    //   document.getElementById("price_to").value = "";
    // } 
    // e.preventDefault();
  };
  const deleteDropdown = (title, name) => {
    setSelected({
      name: title,
      value: data?.[title].filter((item) => item !== name),
    });
    const n = [...data[title]].filter((e) => e !== name);
    const newData = { ...data, [title]: n };
    if (n.length === 0) {
      delete newData[title];
    }
    if (newData.filter_name) {
      newData["filter_name"] = null;
    }
    setData(newData);
    setKey(Math.random() * 100 );

  };
  return (
    <div
      id="box"
      className="flex justify-between  text-[14px] text-[#191C24] font-semibold  lg:mr-2"

    >
      {/* {(data?.seen_between || data?.calander || data?.category || data?.search || data?.engagement || data?.price || data?.platform?.length > 0 || data?.niches?.length > 0) && (
        <div className="py-2 min-w-[70px] text-[#93999A]" >
          selected :
        </div>
      )} */}
      <div className="flex gap-2 overflow-y-auto w-full">
      {data?.category && (
        <>
          <FilerItem
            value={data.category}
            title="category"
            deleteDropdown={deleteDropdown}
          />
        </>
      )}
      {data?.platform && (
        <>
          <FilerItem
            value={data.platform}
            title="platform"
            deleteDropdown={deleteDropdown}
          />
        </>
      )}
      {data?.niches && (
        <>
          <FilerItem
            value={data.niches}
            title="niches"
            deleteDropdown={deleteDropdown}
          />
        </>
      )}
      {data?.social_media && (
        <>
          <FilerItem
            value={data.social_media}
            title="social_media"
            deleteDropdown={deleteDropdown}
          />
        </>
      )}

      {data?.price && (
        <div        className="flex gap-1 items-center whitespace-nowrap ] rounded-full bg-[rgba(255,237,213,255)] py-2 mr-2 px-3"
>
          price: {data.price.from + " - " + data.price.to}
          <XIcon className="w-4 text-[rgba(255,108,61,255)]" onClick={() => deleteFilter('price')} color='text-orange-500' />
        </div>
      )}
      {data?.engagement && (
        <div        className="flex gap-1 items-center whitespace-nowrap ] rounded-full bg-[rgba(255,237,213,255)] py-2 mr-2 px-3"
>
          engagement :{data.engagement.from + " - " + data.engagement.to}
          <XIcon className="w-4 text-[rgba(255,108,61,255)]" onClick={() => deleteFilter('engagement')} color='text-orange-500' />
        </div>
      )}

      {data?.search && (
        <div        className="flex gap-1 items-center whitespace-nowrap ] rounded-full bg-[rgba(255,237,213,255)] py-2 mr-2 px-3"
>
          {data.search}
          <XIcon className="w-4 text-[rgba(255,108,61,255)]" onClick={() => deleteFilter('search')} color='text-orange-500' />
          {/* <button
            className="px-1 font-bold"
            onClick={deleteFilter}
            name="search"
          >
            X
          </button> */}
        </div>
      )}
 {data?.calander && (
        <div className="flex gap-1 items-center whitespace-nowrap ] rounded-full bg-[rgba(255,237,213,255)] py-2 mr-2 px-3">
          <p style={{ fontSize: '16px', lineHeight: '1' }}>
            {(
              new Date(data.calander.start).toLocaleDateString("en-GB", {
                month: "short",
                year: "numeric",
                day: "numeric",
              }) +
              "~" +
              new Date(data.calander.end).toLocaleDateString("en-GB", {
                month: "short",
                year: "numeric",
                day: "numeric",
              })
            )
              .split(" ")
              .join("-")}
          </p>
          <XIcon className="w-4 text-[rgba(255,108,61,255)]" onClick={() => deleteFilter('calander')} color='text-orange-500' />
          {/* <button
            className="px-1 font-bold"
            onClick={deleteFilter}
            name="calander"
          >
            X
          </button> */}
        </div>
      )}
      {data?.seen_between && (
        <div className="flex gap-1 items-center whitespace-nowrap ] rounded-full bg-[rgba(255,237,213,255)] py-2 mr-2 px-3">
          <p style={{ fontSize: '16px', lineHeight: '1' }}>
            {(
              new Date(data.seen_between.start).toLocaleDateString("en-GB", {
                month: "short",
                year: "numeric",
                day: "numeric",
              }) +
              "~" +
              new Date(data.seen_between.end).toLocaleDateString("en-GB", {
                month: "short",
                year: "numeric",
                day: "numeric",
              })
            )
              .split(" ")
              .join("-")}
          </p>
          <XIcon className="w-4 text-[rgba(255,108,61,255)]" onClick={() => deleteFilter('seen_between')} color='text-orange-500' />
        </div>
      )}
</div>
      {(data?.seen_between || data?.calander || data?.category || data?.search || data?.engagement || data?.price || data?.platform|| data?.niches) && (
        <button className="flex  text-[16px] text-[#191C24] font-semibold min-w-[80px]" onClick={(e) => {
          e.preventDefault();
          resetFilter();
        }}>
          Clear all
        </button>
      )}

    </div>
  );
}
