import React, { Fragment, useEffect, useState } from "react";
import { SearchIcon } from "@heroicons/react/solid";
import { Menu, Transition } from "@headlessui/react";
export default function MinReactions({
  data,
  setData,
  reset,
  refreshKey,
  clearAsyncValue,
  initial,
  value,
  setValue,
  userInfo,
  setKey,
  disable
}) {
  const init = { name: "like", min: "" };
  useEffect(() => {
    if (!data.reaction?.min) {
      setVal(init);
    }
  }, [data.reaction]);

  useEffect(() => {
    if (data.reaction) {
      setVal(data.reaction);
    }
  }, [data.filter_name]);

  const [val, setVal] = useState(init);

  return (
    <div className=" text-[#6B7280]  w-full h-full text-[14px] ">
      <Menu as="div" className={"relative"}>
        <div>
          <Menu.Button       disabled={ disable} className="w-full h-full relative ">
            <div className=" ">
              <div className="  absolute inset-y-2 left-[10px] ">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5"
                  />
                </svg>
              </div>
              <div  className={`py-[7px] text-left bg-white  border rounded-3xl border-[#E2E8F0] focus:ring-orange-500 bg-gray-100 focus:border-orange-500 ${(disable) && "bg-[#f2f2f2]"}`}  >
                <div className="ml-[31px] mr-2">
                  <span className=" line-clamp-1 break-all">
                    {data?.reaction?.min > 0 ? (
                      <>{data.reaction.min + " " + `${data.reaction.name}s`}</>
                    ) : (
                      "Reactions "
                    )}
                  </span>
                </div>
              </div>
            </div>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items as={Fragment} className="absolute ">
            <form
              key={
                typeof refreshKey === "string" && refreshKey?.includes("clear")
                  ? refreshKey
                  : null
              }
              onSubmit={(e) => {
                e.preventDefault();
                setValue((prev) => ({ ...prev, reaction: val }));
                setData((prev) => ({ ...prev, reaction: val }));
                setKey(Math.random() * 100 + "apply");
                if (data?.filter_name) {
                  clearAsyncValue();
                }
              }}
              style={{ zIndex: "80" }}
              className="right-0 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg p-2 min-w-[200px]"
            >
              <div className=" flex flex-col gap-2  ">
                <select
                  value={val.name}
                  name="reaction"
                  id="reaction"

                  style={{ outline: "none", boxShadow: "none" }}
                  onChange={(e) => {
                    setVal((prev) => ({ name: e.target.value, min: prev.min }));
                  }}
                  className="form-select px-2 w-full h-[38px] text-sm md:text-base font-normal  border border-solid border-[#CBD5D7] rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-orange-500 focus:outline-none"
                >
                  <option value="like">Likes</option>
                  <option value="comment">Comments</option>
                  <option value="share">Shares</option>
                </select>
                <input
                  min="0"
                  value={val.min}
                  name="min"
                  id="min"
                  onChange={(e) => {
                    setVal((prev) => ({
                      name: prev.name,
                      min: e.target.value,
                    }));
                  }}
                  style={{ outline: "unset", boxShadow: "unset" }}
                  className=" px-2 h-[38px] w-full text-sm md:text-base font-normal border border-solid border-[#c5cdcf] rounded  focus:text-gray-700 focus:bg-white focus:border-orange-500  focus:outline-none"
                  placeholder="min"
                  type="number"
                  required
                />{" "}
                <Menu.Item>
                  <button
                    type="submit"
                    className=" border border-solid h-[30px] text-white border-[#ff6c3d] bg-[#ff6c3d] rounded-3xl transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-orange-400 focus:outline-none  px-2  flex items-center"
                  >
                    <span className="m-auto">Apply</span>
                  </button>
                </Menu.Item>
              </div>
            </form>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}



