// import { useAuth0 } from '@auth0/auth0-react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
// import { useEffect } from 'react';
// import { useState } from 'react';
// import API from '../../API';
// import Loading from '../../components/loading';
import CheckoutForm from './CheckoutForm';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.



const stripePromise = loadStripe('pk_test_51HW144AWdNl5Wrw1UD4mjQ11YC6QBKMjjA9J13irlITonLeNsI2fucP5BMqIwFpFPizNSYPNTgFMh8GxVdf6gmFA00HbsXyLee');

export default function SubscriptionForm({ plan }) {
    // const [loading, setLoading] = useState(true)
    // const [options, setOptions] = useState('')
    // const { user, getAccessTokenSilently } = useAuth0();
    // const options = {
    //     "clientSecret": "pi_3N5f9GAWdNl5Wrw130CYw1qH_secret_dUV4GZi9MKaLLLXvh42YiunHF"
    // };
    // useEffect(() => {
    //     (async () => {
    //         try {
    //             const res = await API.post(`stripe/create-payment-intent`, {
    //                 amount: plan === 'starter' ? 7700 : plan === 'vip' ? 15500 : plan === 'pro' && 26300
    //             }, {
    //                 headers: {
    //                     Authorization: `Bearer ${await getAccessTokenSilently()}`,
    //                 },
    //             });
    //             setOptions(res.data)
    //             setLoading(false);
    //         } catch (error) {
    //             console.log(error);
    //         }

    //     })();
    // }, []);
    // if (loading) {
    //     return <Loading />
    // }
    return (
        <Elements stripe={stripePromise}  >
            <CheckoutForm plan={plan}/>
        </Elements>
    );
};
