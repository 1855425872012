import React, { Fragment, useEffect, useState } from "react";
import API from "../API";
import LoadingSpinner from "./loading-spinner";
import { Toast } from "./toast";
import { Modal } from "./modal";
import { XIcon } from "@heroicons/react/outline";

const lang = [
  { value: "en", label: "English" },
  { value: "es", label: "Spanish" },
  { value: "de", label: "German" },
  { value: "da", label: "Denmark" },
  { value: "ro", label: "Romanian" },
  { value: "et", label: "Estonian" },
  { value: "ar", label: "Arabic" },
  { value: "fr", label: "French" },
  { value: "ur", label: "Urdu" },
  { value: "nl", label: "Flemish" },
  { value: "ja", label: "Japanese" },
  { value: "pt", label: "Portuguese" },
  { value: "cs", label: "Czech" },
  { value: "tl", label: "Tagalog" },
];

function changeValue(value) {
  if (value.includes(" ")) {
    value = value
      .split(" ")
      .filter((e) => e !== " ")
      .join(" ");
  }
  if (value.includes("_")) {
    value = value
      .split("_")
      .filter((e) => e !== "_")
      .join(" ");
  }
  return value;
}

const FilerItem = ({ value, title, data, setData, setValue, clearAll,setKey }) => {


  if (
    Array.isArray(value) &&
    title !== "search" &&
    title !== "ad_format" &&
    title !== "ads_type"
  ) {
    return value.map((element,i) => {

      const val = changeValue(element);
      return (
        <div key={i} className="flex gap-1 items-center whitespace-nowrap  rounded-full bg-[rgba(255,237,213,255)] py-2 mr-2 px-3">
          <p className="capitalize">
            {title === "t_lang"
              ? lang.map((el) => {
                  if (el.value === val) {
                    return <Fragment key={el.value}>{el.label}</Fragment>;
                  }
                })
              : val}
          </p>
          <div
            className="cursor-pointer text-[rgba(255,108,61,255)]  hover:font-bold"
            onClick={() => {
              const n = [...data[title]].filter((e) => e !== element);
              const newData = { ...data, [title]: n };
              if (n.length === 0) {
                delete newData[title];
              }
              if (newData.filter_name) {
                newData["filter_name"] = null;
              }
              setData(newData);
              setValue((prev) => {
                const n = [...prev[title]].filter((e) => e.value !== element);
                return { ...prev, [title]: n };
              });
              setKey(Math.random() * 100 + "apply");
            }}
          >
            <XIcon className="w-5" aria-hidden="true" />
          </div>
        </div>
      );
    });
  }
  return null;
};

export default function List({
  data,
  setData,
  init,
  setKey,
  setSkip,
  skip,
  setShowInput,
  showInput,
  filterValue,
  setFilterValue,
  clearAsyncValue,
  setValue,
  initialValue,
  userInfo,
  setfirstLoad,
  setSearch,
  resetSearchTerm,
  setIsSaveTriggered,
  disable
}) {
  const isFilterChanged =
    Object.keys(data).length > 3 || data.sort !== "last_seen";
  const [savingFilters, setSavingFilters] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [error, setError] = useState("");
  async function saveFilters() {
    try {
      setSavingFilters(true);
      await API.post(
        `filters/save?user_id=${
          userInfo._id
        }&filter_name=${filterValue}&social_type=${
          data.social_type
        }&filters=${JSON.stringify(data)}`
      ).then((e) => {
        setSavingFilters(false);
        setShowToast({ status: e.status, message: e.data.message });
      });
    } catch (error) {
      setSavingFilters(false);
      console.log(error, "save filters error");
    }
  }

  useEffect(() => {
    if (showToast) {
      const timeout = setTimeout(() => {
        setShowToast(null);
      }, 4000);
      return () => clearTimeout(timeout);
    }
  }, [showToast]);

  function clearAll() {
    if (skip > 0) {
      setSkip(0);
    }

    const nd = { ...init, social_type: data.social_type , ads_type:data.ads_type};
    setData(nd);
     setValue({ ...initialValue, social_type: data.social_type ,ads_type:data.ads_type});
     setfirstLoad(false)
     setSearch({
      value: "",
      type:  "ad_description",
      label: "Ad Description",
     });
     setKey(Math.random() * 100 + "apply");

  }


  return (
    <div
      id="box"
      className="flex justify-between  text-[14px] text-[#191C24] font-semibold  lg:mr-2"
    >
      <div className="overflow-y-auto flex items-center">
        {Object.keys(data).map((filterItem, i) => {
          return (
            <FilerItem
              key={i}
              value={data[filterItem]}
              title={filterItem}
              data={data}
              setData={setData}
              setValue={setValue}
              clearAll={clearAll}
              setKey={setKey}
            />
          );
        })}
        {data?.sort && data?.sort !== "last_seen" && (
          <div className="flex gap-1 rounded-full whitespace-nowrap capitalize  py-2 mr-2 px-3 bg-[rgba(255,237,213,255)]">
            {data.sort
              .split("_")
              .filter((e) => e !== "_")
              .join(" ")}
            <div
              className="cursor-pointer text-[rgba(255,108,61,255)]  hover:font-bold"
              onClick={() => {
                if (!disable) {
                  setValue((prev) => ({ ...prev, sort: "last_seen" }));
                  let nd = { ...data };
                  setData({ ...nd, sort: "last_seen" });
                  if (nd.filter_name) {
                    let { filter_name: _, ...nd } = data;
                    setData(nd);
                  }
                }
                setKey(Math.random() * 100 + "apply");
                // if (Object.keys(data).length === 4) {
                //   clearAll();
                // }
              }}
            >
              <XIcon className="w-5" aria-hidden="true" />
            </div>
          </div>
        )}
        {data?.ad_format && data.ad_format.length > 0 && (
          <div className="flex gap-1 rounded-full whitespace-nowrap capitalize  py-2 mr-2 px-3 bg-[rgba(255,237,213,255)]">
            {data.ad_format}
            <div
              className="cursor-pointer text-[rgba(255,108,61,255)]  hover:font-bold"
              onClick={() => {
                if (!disable) {
                setValue((prev) => ({ ...prev, ad_format: null }));
                let { ad_format: _, ...nd } = data;
                setData(nd);
                if (nd.filter_name) {
                  let { filter_name: _, ...nd } = data;
                  setData(nd);
                  clearAsyncValue();
                }
          
              }
              setKey(Math.random() * 100 + "apply");
                // if (Object.keys(data).length === 4) {
                //   clearAll();
                // }
              }}
            >
              <XIcon className="w-5" aria-hidden="true" />
            </div>
          </div>
        )}
        {data?.filter_name && (
          <div className="flex gap-1 rounded-full whitespace-nowrap capitalize  py-2 mr-2 px-3 bg-[rgba(255,237,213,255)]">
            {data?.filter_name}
            <div
              className="cursor-pointer text-[rgba(255,108,61,255)]  hover:font-bold"
              onClick={() => {
                if (!disable) {
                setValue((prev) => ({ ...prev, filter_name: undefined }));
                let { filter_name: _, ...nd } = data;
                setData(nd);
                clearAsyncValue();
                }
                setKey(Math.random() * 100 + "apply");
                // if (Object.keys(data).length === 4) {
                //   clearAll();
                // }
              }}
            >
              <XIcon className="w-5" aria-hidden="true" />
            </div>
          </div>
        )}
        {/* {data?.ads_type?.length > 0 && (
          <div className="flex gap-1 rounded-full whitespace-nowrap capitalize  py-2 mr-2 px-3 bg-[rgba(255,237,213,255)]">
            {data.ads_type[0]}
            <div
              className="cursor-pointer text-[rgba(255,108,61,255)]  hover:font-bold"
              onClick={() => {
                if (!disable) {
                setValue((prev) => ({ ...prev, ads_type: null }));
                let { ads_type: _, ...nd } = data;
                setData(nd);
                if (nd.filter_name) {
                  let { filter_name: _, ...nd } = data;
                  setData(nd);
                  clearAsyncValue();
                    }}
                // if (Object.keys(data).length === 4) {
                //   clearAll();
                // }
              }}
            >
              <XIcon className="w-5" aria-hidden="true" />
            </div>
          </div>
        )} */}
        {data?.reaction?.min > 0 && (
          <div className="flex gap-1 rounded-full whitespace-nowrap capitalize  py-2 mr-2 px-3 bg-[rgba(255,237,213,255)]">
            {data.reaction.min + " " + `${data.reaction.name}s`}
            <div
              className="cursor-pointer text-[rgba(255,108,61,255)]  hover:font-bold"
              onClick={() => {
                if (!disable) {
                setValue((prev) => ({
                  ...prev,
                  reaction: undefined,
                }));
                setData({ ...data, reaction: undefined});
                if (data.filter_name) {
                  let { filter_name: _, ...nd } = data;
                  setData(nd);
                  clearAsyncValue();
                }}
                setKey(Math.random() * 100 + "apply");
                // if (Object.keys(data).length === 4) {
                //   clearAll();
                // }
              }}
            >
              <XIcon className="w-5" aria-hidden="true" />
            </div>
          </div>
        )}
        {data.search && data.search.length > 0 && data.search[0] !== "" || data?.query ? (
          <div className="flex gap-1 rounded-full whitespace-nowrap  py-2 mr-2 px-3 bg-[rgba(255,237,213,255)]">
            {data?.query || decodeURIComponent(data.search[0])}
            <div
              className="cursor-pointer text-[rgba(255,108,61,255)]  hover:font-bold"
              onClick={() => {
                if (!disable) {
                setValue((prev) => ({ ...prev, search: undefined}));
                setSearch((prev) => ({
                  value: "",
                  type: prev.type ?? "ad_description",
                  label: prev.label ?? "Ad Description",
                }));
                let { search: _,query,fetured, ...nd } = data;
                setData(nd);
                if (nd.filter_name) {
                  let { filter_name: _, ...nd } = data;
                  setData(nd);
                  clearAsyncValue();
                }}
                setKey(Math.random() * 100 + "apply");
                // if (Object.keys(data).length === 4) {
                //   clearAll();
                // }
              }}
            >
              <XIcon className="w-5" aria-hidden="true" />
            </div>
          </div>
        ) : null}
        {data?.calander && (
          <div className="flex gap-1 rounded-full whitespace-nowrap  py-2 mr-2 px-3 bg-[rgba(255,237,213,255)]">
            <p>
              {
                new Date(data.calander.start).toLocaleDateString("en-GB", {
                  month: "short",
                  year: "numeric",
                  day: "numeric",
                }) +
                  "~" +
                  new Date(data.calander.end).toLocaleDateString("en-GB", {
                    month: "short",
                    year: "numeric",
                    day: "numeric",
                  })
                // .split(" ")
                // .join(" ")
              }
            </p>
            <div
              className="cursor-pointer text-[rgba(255,108,61,255)]  hover:font-bold"
              onClick={() => {
                if (!disable) {
                setValue((prev) => ({ ...prev, calander: undefined }));
                let { calander: _, ...nd } = data;
                setData(nd);
                if (nd.filter_name) {
                  let { filter_name: _, ...nd } = data;
                  setData(nd);
                  clearAsyncValue();
                }}
                setKey(Math.random() * 100 + "apply");
                // if (Object.keys(data).length === 4) {
                //   clearAll();
                // }
              }}
            >
              <XIcon className="w-5" aria-hidden="true" />
            </div>
          </div>
        )}
        {data?.seen_between && (
          <div className="flex gap-1 rounded-full whitespace-nowrap   py-2 mr-2 px-3 bg-[rgba(255,237,213,255)]">
            <p>
              {
                new Date(data.seen_between.start).toLocaleDateString("en-GB", {
                  month: "short",
                  year: "numeric",
                  day: "numeric",
                }) +
                  "~" +
                  new Date(data.seen_between.end).toLocaleDateString("en-GB", {
                    month: "short",
                    year: "numeric",
                    day: "numeric",
                  })
                // .split(" ")
                // .join(",")
              }
            </p>
            <div
              className="cursor-pointer text-[rgba(255,108,61,255)]  hover:font-bold"
              onClick={() => {
                if (!disable) {
                setValue((prev) => ({ ...prev, seen_between: undefined }));
                let { seen_between: _, ...nd } = data;
                setData(nd);
                if (nd.filter_name) {
                  let { filter_name: _, ...nd } = data;
                  setData(nd);
                  clearAsyncValue();
                }}
                setKey(Math.random() * 100 + "apply");
                // if (Object.keys(data).length === 4) {
                //   clearAll();
                // }
              }}
            >
              <XIcon className="w-5" aria-hidden="true" />
            </div>
          </div>
        )}
        {showToast && <Toast setClose={setShowToast} data={showToast} />}
        {showInput && (
          <Modal
            title={"Filter name"}
            show={showInput}
            setShowModal={setShowInput}
            message={
              <Fragment>
                <div className="mb-2">choose a name for your filter:</div>
                <input
                  className="text-sm w-full h-[38] border border-[#cccccc] rounded py-2 px-3 focus:!border-[#2684FF] focus:border-2 outline-none"
                  value={filterValue}
                  placeholder="Name your filter here"
                  onChange={(e) => setFilterValue(e.target.value)}
                  required
                />
                {error && (
                  <div className="text-sm text-pink-500 mt-1">{error}</div>
                )}
              </Fragment>
            }
            actions={
              <Fragment>
                <button
                  className="px-8 py-2 shadow-sm text-sm font-medium rounded-md bg-orange-500 hover:bg-[#e6662b] text-white focus:outline-none focus:ring-[#e6662b]"
                  onClick={() => {
                    if (showInput && filterValue === "") {
                      setError("Filter name shouldn't be empty");
                    }
                    setShowInput(true);
                    if (filterValue) {
                      saveFilters();
                      setFilterValue("");
                      setError("");
                      setShowInput(false);
                      setIsSaveTriggered(true);
                    }
                  }}
                >
                  {savingFilters ? <LoadingSpinner /> : "Save filter"}
                </button>
                <button
                  className="inline-flex justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-black hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-300 focus-visible:ring-offset-2"
                  onClick={() => setShowInput(false)}
                >
                  Cancel
                </button>
              </Fragment>
            }
          />
        )}
      </div>
      <div className="flex  text-[16px] text-[#191C24] font-semibold">
        {isFilterChanged && (
          <Fragment>
            <button
              className="p-2 min-w-[80px]  "
              onClick={(e) => {
                // e.preventDefault();
                clearAll();
              }}
            >
              Clear all
            </button>
            {!data.filter_name && (
              <>
                {" "}
                <span className="py-2 ">|</span>
                <div
                  className=" cursor-pointer p-2   whitespace-nowrap "
                  onClick={() => {
                    setShowInput(true);
                  }}
                >
                  {savingFilters ? <LoadingSpinner /> : "Save filter"}
                </div>
              </>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
}
