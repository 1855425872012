import React from 'react';
// import '../../../src/App.css';
import '../App.css'
// type Props = {
// 	className?: string
// 	relative?: boolean
// }
export default function LoadingSpinner(props){
	return (
		<span
			className={`loadingSpinner ${props.className || ''} ${props.relative ? 'relative' : ''}`}
		/>
	);
};
