import React from "react";
import {
  CalendarIcon,
  ChatIcon,
  EyeIcon,
  EyeOffIcon,
  ShareIcon,
  ThumbUpIcon,
} from "@heroicons/react/outline";
import {
  lastseenDateCount,
  SeenDate,
  kFormatter,
} from "../controllers/actions";
import { fShortenNumber } from "../utils/formatNumber";

const StatItem = ({ value, label, icon, iconColor, classN }) => {
  return (
    <div
      className={`flex flex-col items-center  w-full px-1.5 ${classN ?? ""}`}
    >
      <span className="text-m font-medium mb-1">{value}</span>
      <span
        className={`text-[11px] font-regular text-[#bdbfbf]  flex justify-center items-center`}
      >
        {icon}
        {label}
      </span>
    </div>
  );
};
const Item = ({ value, label, icon, iconColor, classN }) => {
  return (
    <div
      className={`flex flex-col items-center w-full  px-1 bg-[#292722e4] rounded-md border border-[1px] `}
    >
      {/* <span className="text-m font-medium mb-1">{value}</span> */}
      <span
        className={`text-[15px] font-regular text-[#ffffff]  flex justify-center items-center`}
      >
        {icon}
        {value}
      </span>
    </div>
  );
};

export default function PostStats({ post, fullStats, pip, hide }) {
  return (
    <div className=" w-full  ">
      {fullStats ? (
        <div className="shadow-[0px_0.5px_0px_0px_rgba(0,0,0,0.20)] py-4">
          <div className="flex justify-evenly w-full px-1">
            <StatItem
              value={
                fullStats
                  ? fShortenNumber(
                    post?.video_views ? post?.video_views : post.like * 220
                  )
                  : fShortenNumber(
                    post?.video_views ? post?.video_views : post.like * 220
                  )
              }
              label="Impression"
              icon={<EyeIcon className="mr-1 w-4 " color="#F2773C" />}
              iconColor="text-red-500"
            />
            <StatItem
              value={fullStats ? post.like : kFormatter(post.like)}
              label="Likes"
              icon={<ThumbUpIcon className="mr-1 w-4" color="#F2773C" />}
              iconColor="text-red-500"
              classN="border border-y-0 border-r-0 border-gray-200"
            />
            <StatItem
              value={fullStats ? post.comment : kFormatter(post.comment)}
              label="Comments"
              icon={<ChatIcon className="mr-1 w-4" color="#F2773C" />}
              iconColor="text-teal-500"
              classN="border border-y-0 border-gray-200"
            />
            <StatItem
              value={fullStats ? post.share : kFormatter(post.share)}
              label="Shares"
              icon={<ShareIcon className="mr-1 w-4" color="#F2773C" />}
              iconColor="text-orange-500"
            />
          </div>

          <div className="flex justify-between mt-5 w-full">
            <StatItem
              value={SeenDate(post?.post_create, post?.last_seen)}
              label="Creation date"
              icon={<EyeIcon className="mr-2 w-4" />}
              iconColor="text-gray-600"
            />
            {post.productsRelated ?
              
            <StatItem
            value={post?.productsRelated?.length}
            label="Ads"
            classN=" !w-[50px] "
          />:
              <StatItem
                value={lastseenDateCount(post?.post_create)}
                label="Days Old"
                icon={<CalendarIcon className="mr-2 w-4" />}
                iconColor="text-gray-600"
                classN="border border-y-0 border-gray-200"
              />
            }

            <StatItem
              value={SeenDate(post?.last_seen, post?.last_seen)}
              label="Last seen"
              icon={<EyeOffIcon className="mr-2 w-4" />}
              iconColor="text-gray-600"
            />
          </div>
        </div>
      ) : (
        <div
          className={`flex justify-center gap-1  absolute  ${pip && hide ? "hidden" : "bottom-[120px]  px-4 w-full"
            }`}
        >
          <Item
            value={fShortenNumber(
              post?.video_views ? post?.video_views : post.like * 220
            )}
            label="Impression"
            icon={<EyeIcon className="mr-1 w-4 " color="#ece9e2" />}
          />
          <Item
            value={kFormatter(post.like)}
            label="Likes"
            icon={<ThumbUpIcon className="mr-1 w-4" color="#ece9e2" />}
          />
          <Item
            value={kFormatter(post.comment)}
            label="Comments"
            icon={<ChatIcon className="mr-1 w-4" color="#ece9e2" />}
          />
          <Item
            value={kFormatter(post.share)}
            label="Shares"
            icon={<ShareIcon className="mr-1 w-4" color="#ece9e2" />}
          />
        </div>
      )}
    </div>
  );
}
