import { useEffect } from "react";
import { MdSearch } from "react-icons/md";
import { useLocation } from "react-router-dom";
import Select from "react-select";

export default function Searchbar({
  refreshKey,
  className,
  userInfo,
  search,
  setSearch,
  setData,
  data,
  setSkip,
  skip,
  setKey,
  disable
}) {
  const location = useLocation();
  const routes =
    location.pathname === "/products_research" ||
    location.pathname === "/aliexpress_products"
      ? [{ id: 1, label: "Product Title", value: "product_title" }]
      : [
          { id: 1, label: "Ad Description", value: "ad_description" },
          { id: 2, label: "Ad Title", value: "ads_title" },
          { id: 3, label: "Advertiser", value: "advertiser" },
          { id: 4, label: "Landing Page", value: "landing_page" },
        ];

  useEffect(() => {
    if (typeof refreshKey === "string" && refreshKey?.includes("clear")) {
      setSearch({ value: "", label: "Ad Description", type: "ad_description" });
    }
  }, [refreshKey]);

  useEffect(() => {
    if (
      data.search &&
      data.search_type &&
      data.search.length > 0 &&
      data.search[0] !== ""
    ) {
      const s = routes.find((route) => route.value === data.search_type);
      setSearch({
        value: decodeURIComponent(data.search[0]),
        label: s.label,
        type: s.value,
      });
    }
  }, [data?.filter_name]);

  return (
    <div className={`flex justify-center ${className ?? ""}`}>
      <div className="relative w-full ">
        {/* <MdSearch size={28} style={{position: 'absolute', left: 10, top: '50%', bottom: '50%', transform: 'translate(0,-50%)' }} /> */}
        <div className="z-20 m-auto absolute inset-y-2 left-[10px]  text-[#6B7280]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 mr-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"

              d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
            />
          </svg>
        </div>

        <input
          disabled={ disable}
          type="search"
          id="search"
          name="search"
          value={search.value}
          onChange={(e) => {
            setSearch((prev) => ({
              type: prev.type,
              label: prev.label,
              value: e.target.value,
            }));
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (skip > 0) {
                setSkip(0);
              }
              setData((prev) => ({
                ...prev,
                search: [encodeURIComponent(search.value)],
                search_type: search.type,
              }));
              setKey(Math.random() * 100 + "apply");
            }
          }}
          className={`block pr-2 py-[6px] pl-8 w-full bg-white z-20 text-[14px] text-[#6B7280] border rounded-3xl border-[#E2E8F0] focus:ring-orange-500 focus:border-orange-500 ${( disable) && "bg-[#f2f2f2]"}`}
          placeholder="Search By Keywords..."
          required
        />
      </div>
      {/* Ad_Description by default */}
      {/* <div className="z-10 selectParent" >
				<Select 
					className='min-w-[200px]'
					key={typeof refreshKey === 'string' && (refreshKey?.includes('clear') || refreshKey?.includes('value')) ? refreshKey : null}
					isDisabled={!userInfo?.stripe_subscription}
					isSearchable
					options={routes} 
					value={{value: search.value, label: search.label}}
					onChange={(e) => {
						setSearch(prev => ({value: prev.value, type: e.value, label: e.label}));
						if(search.value.length > 0){
							setData(prev => ({...prev, search: [search.value], search_type: e.value}));
						}
					}}
				/>
			</div> */}
    </div>
  );
}

export  function Search({
  className,
  userInfo,
  search,
  setSearch,
  setData,
  data,
  setSkip,
  skip

}) {

  const location = useLocation();
  const routes =
    location.pathname === "/products_research" ||
    location.pathname === "/aliexpress_products"
      ? [{ id: 1, label: "Product Title", value: "product_title" }]
      : [
          { id: 1, label: "Ad Description", value: "ad_description" },
          { id: 2, label: "Ad Title", value: "ads_title" },
          { id: 3, label: "Advertiser", value: "advertiser" },
          { id: 4, label: "Landing Page", value: "landing_page" },
        ];

  useEffect(() => {
    // if (typeof refreshKey === "string" && refreshKey?.includes("clear")) {
      setSearch({ value: "", label: "Ad Description", type: "ad_description" });
    // }
  }, []);

  useEffect(() => {
    if (
      data.search &&
      data.search_type &&
      data.search.length > 0 &&
      data.search[0] !== ""
    ) {
      const s = routes.find((route) => route.value === data.search_type);
      setSearch({
        value: decodeURIComponent(data.search[0]),
        label: s.label,
        type: s.value,
      });
    }
  }, [data?.filter_name]);

  return (
    <div className={`flex justify-center ${className ?? ""}`}>
      <div className="relative w-full ">
        {/* <MdSearch size={28} style={{position: 'absolute', left: 10, top: '50%', bottom: '50%', transform: 'translate(0,-50%)' }} /> */}
        <div className="z-20 m-auto absolute inset-y-2 left-[10px]  text-[#6B7280]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 mr-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"

              d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
            />
          </svg>
        </div>

        <input
          type="search"
          id="search"
          name="search"
          // value={search.value}
          onChange={(e) => {
            setSearch((prev) => ({
              type: prev.type,
              label: prev.label,
              value: e.target.value,
            }));
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (skip > 0) {
                setSkip(0);
              }
              setData((prev) => ({
                ...prev,
                search: [encodeURIComponent(e.target.value)],
                search_type: search.type,
              }));
       
            }
          }}
          className={`block pr-2 py-[6px] pl-8 w-full bg-white z-20 text-[14px] text-[#6B7280] border rounded-3xl border-[#E2E8F0] focus:ring-orange-500 focus:border-orange-500 `}
          placeholder="Search By Keywords..."
          required
        />
      </div>
      {/* Ad_Description by default */}
      {/* <div className="z-10 selectParent" >
				<Select 
					className='min-w-[200px]'
					key={typeof refreshKey === 'string' && (refreshKey?.includes('clear') || refreshKey?.includes('value')) ? refreshKey : null}
					isDisabled={!userInfo?.stripe_subscription}
					isSearchable
					options={routes} 
					value={{value: search.value, label: search.label}}
					onChange={(e) => {
						setSearch(prev => ({value: prev.value, type: e.value, label: e.label}));
						if(search.value.length > 0){
							setData(prev => ({...prev, search: [search.value], search_type: e.value}));
						}
					}}
				/>
			</div> */}
    </div>
  );
}


export  function SearchAli({ refreshKey, className, userInfo, search, setSearch, setData, data, setSkip, skip, setKey }) {
  const location = useLocation();
  const routes = location.pathname === '/products_Research' || location.pathname === '/aliexpress_products' ? [
    { id: 1, label: 'Product Title', value: 'product_title' },
  ] : [
		{ id: 1, label: 'Ad Description', value: 'ad_description' },
    { id: 2, label: 'Ad Title', value: 'ads_title' },
    { id: 3, label: 'Advertiser', value: 'advertiser' },
    { id: 4, label: 'Landing Page', value: 'landing_page' },
  ]

  // useEffect(() => {
  //   if(typeof refreshKey === 'string' && refreshKey?.includes('clear')){
  //     setSearch({value: '', label: 'Ad Description', type: 'ad_description'})
  //   }
  // }, [refreshKey]);

	useEffect(() => {
		if(data.search && data.search_type && data.search.length > 0 && data.search[0] !== ''){
			const s = routes.find(route => route.value === data.search_type);
			setSearch({value: decodeURIComponent(data.search[0]), label: s.label, type: s.value});
		}
  }, [data?.filter_name]);

  return (
		<div className={`flex justify-center ${className ?? ''}`}>
			<div className="relative z-30 w-full" >
				<MdSearch size={20} style={{ position: 'absolute', left: 10, top: '50%', bottom: '50%', transform: 'translate(0,-50%)' }} />
				<input
					type="search"
					id="search"
					name="search"
					// value={search.value}
					onChange={e => {
						setSearch(prev => ({type: prev.type, label: prev.label, value: e.target.value}));
					}}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							if(skip > 0){
								setSkip(0);
							}
							setData(prev => ({...prev, search: [encodeURIComponent(search.value)], search_type: search.type}));
							setKey(Math.random()*100+'apply');
						}
					}}
					className="block pr-2 py-2 pl-10 w-full bg-white z-20 text-sm text-gray-900 border rounded-[5px] border-[#BDBDBD] focus:ring-orange-500 focus:border-orange-500"
					placeholder="Add your keywords" 
					required 
				/>
			</div>
			{/* Ad_Description by default */}
			{/* <div className="z-10 selectParent" >
				<Select 
					className='min-w-[200px]'
					key={typeof refreshKey === 'string' && (refreshKey?.includes('clear') || refreshKey?.includes('value')) ? refreshKey : null}
					isDisabled={!userInfo?.stripe_subscription}
					isSearchable
					options={routes} 
					value={{value: search.value, label: search.label}}
					onChange={(e) => {
						setSearch(prev => ({value: prev.value, type: e.value, label: e.label}));
						if(search.value.length > 0){
							setData(prev => ({...prev, search: [search.value], search_type: e.value}));
						}
					}}
				/>
			</div> */}
		</div>
  )
}