import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';
import App from './App';
import { Auth0Provider } from "@auth0/auth0-react";


const root = ReactDOM.createRoot(document.getElementById('root'));
// const DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN
// const CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID
// const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL
const DOMAIN = 'adsteak.us.auth0.com'
const CLIENT_ID = 'd3tV2adBHZRFAqd2tVqetQi3m0cUUyUj'
const REACT_APP_BASE_URL = 'https://adsteak.com/alladspy-backend'
// const DOMAIN = 'dev-gc0nnfy4a1kybt8m.us.auth0.com'
// const CLIENT_ID = 'nTlJJlGNAsrxCtjp62ee3wjxAjizZYz0'
// const REACT_APP_BASE_URL = 'https://adsteak-e7xdxy24bq-uc.a.run.app'


root.render(
  <Auth0Provider
    domain={DOMAIN}
    clientId={CLIENT_ID}
    redirectUri={window.location.origin}
    audience={REACT_APP_BASE_URL}
    // authorizationParams={{
    //     redirect_uri: window.location.origin,
    //   }}
  // audience={'https://localhost:8000/alladspy-backend'}
  >
		<App />
  </Auth0Provider>,

);


