import "./App.css";
import Home from "./components/Home";
import NavBar from "./components/NavBar";
import { useAuth0 } from "@auth0/auth0-react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Favorites from "./components/Favorite";

import { Fragment, useEffect, useState } from "react";
import LandingPage from "./components/landing-page";
import PostTable from "./components/admin/post-table";
import AliExpress from "./pages/ali-express/AliExpress";
import ProductDetails from "./pages/ali-express/ProductDetails";
import ProductDetailsStore from "./pages/store/ProductDetails";
import Store from "./pages/store/Store";
import Draft from "./pages/draft/Draft";
import PlansPage from "./pages/payment/Plans";
import Footer from "./components/Footer";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ContactUs from "./pages/ContactUs";
import { Upload } from "./pages/upload";
import Terms from "./pages/terms";
import API from "./API";
import Welcome from "./pages/Welcome";

function App() {
  const [Search, setSearch] = useState();
  const { isAuthenticated, isLoading, user, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const [userInfo, setUserInfo] = useState(null);
  const [queries, setQueries] = useState();
	const [isUpdatedUser, setIsUpdatedUser] = useState(true);
  const [Update, setUpdate] = useState();
  const [plan, setPlan] = useState();
//anep
	const init = {
    sort: "last_seen",
    fetured: false,
    social_type: 'facebook'
  };
  const [data, setData] = useState(init);
  async function getUser(posts){
    if(isAuthenticated && !isLoading && user ){
      const route = `user?email=${user?.email}&customer_id=${user?.customer_id}&posts=${posts}`
      const userData = await API.get(route, {
        headers: {
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      });
      setUserInfo(userData.data.user);
      setQueries(userData.data.queries)
      setPlan(userData.data.plan)
      // if(!userData.data.user.admin && !userData.data.user.stripe_subscription ){

      //   const paymentLink = userData.data.user.customer_id ? process.env.REACT_APP_STRIPE_PAYMENT_LINK_WITHOUT_TRIAL : process.env.REACT_APP_STRIPE_PAYMENT_LINK ;

      //   // redirect to stripe
      //   const basicPlan = userData && `${paymentLink}?client_reference_id=${userData.data.user._id}&prefilled_email=${userData.data.user.email}`
      //   window.location = (basicPlan)

      // }

    }
  }

  useEffect(() => {
		getUser();
  }, [isAuthenticated,Update]);

	useEffect(() => {
		if(typeof isUpdatedUser === 'number'){
			getUser(isUpdatedUser);
			setIsUpdatedUser(undefined);
		}
  }, [isUpdatedUser]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      if(window.location.search.indexOf("signup") !== -1){
        console.log("to signup")
        loginWithRedirect({
          screen_hint: "signup",
        })
      }else{
        loginWithRedirect();
      }
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (isLoading) {
    return (
      <div className="w-full h-screen flex flex-col items-center justify-center">
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
        {/* <p className="text-sm">Loading...</p> */}
      </div>
    );
  }
  const handleSearch = (e) => {
    try {
      e.preventDefault();

      let search = e.target.search.value
        .replace(/[&/\\#,+()$~%.'":*?<>{}]/g, "")
      setSearch(search);

    } catch (error) {
      console.log(error);
    }
  };

  
  return (
    <Fragment>
      <div>
        <div className="h-full bg-slate-100">
          <Router>
            {isAuthenticated ? (
              <Fragment>
                <NavBar userInfo={userInfo} queries={queries} handleSearch={handleSearch} setUpdate={setUpdate} plan={plan} />
                <div className="mt-[80px]">
                  <Routes>
                    <Route path="/" element={<Home init={init} setIsUpdatedUser={setIsUpdatedUser}  userInfo={userInfo} data={data} setData={setData} />} />
                    <Route path="/upload" element={<Upload />} />
                    <Route exact path="/ads_staff_picks" element={<Home init={init} setIsUpdatedUser={setIsUpdatedUser}  userInfo={userInfo} setData={setData} data={{...data, fetured: true}} />} />
                    <Route exact path="/favorites" element={<Favorites userInfo={userInfo} />} />
                    <Route exact path="/privacy_policy" element={<PrivacyPolicy />} />
                    <Route exact path="/terms" element={<Terms />} />
                    <Route exact path="/contact_us" element={<ContactUs />} />
                    <Route exact path="/welcome" element={<Welcome />} />
                    <Route exact path="/aliexpress_products" element={<AliExpress userInfo={userInfo} />} />
                    <Route path="/aliexpress_products/:productId" element={<ProductDetails userInfo={userInfo} />} />
                    <Route exact path="/products_research" element={<Store userInfo={userInfo} />} />
                    <Route path="/products_research/:productId" element={<ProductDetailsStore   userInfo={userInfo}/>} />
                    <Route exact path="/payment" element={<PlansPage userInfo={userInfo} />} />
                    {isAuthenticated && user?.role?.includes("admin") && <Route exact path="/admin" element={<PostTable Search={Search} />} />}
                    {isAuthenticated && user?.role?.includes("admin") && <Route exact path="/draft" element={<Draft />} />}
                  </Routes>
                  <Footer />
                </div>
              </Fragment>
            ) : (
              // <Routes>
              //   {/* <Route exact path="/payment" element={<PlansPage />} /> */}
              //   <Route exact path="*" element={<LandingPage />} />
              // </Routes>
              <div>
                {/* Loading... */}


              </div>
            )}
          </Router>
        </div>
      </div>
    </Fragment>
  );
}

export default App;
