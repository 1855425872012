import React, { useEffect } from "react";
import API from "../API";
import { BookmarkIcon as OutlineBookmarkIcon } from "@heroicons/react/outline";
import {
  BookmarkIcon as SolidBookmarkIcon,
  CheckIcon,
} from "@heroicons/react/solid";
import { useAuth0 } from "@auth0/auth0-react";

export default function AddToDraftBtn({ post, myDraftList, setMyDraftList }) {
  const { user, getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    return () => {};
  }, []);
  const addtoDrafte = async () => {
    try {
      API.post(
        `/draft`,
        {
          email: user.email,
          post: post._id,
        },
        {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        }
      ).then((res) => {
        setMyDraftList((prev) => [
          ...prev,
          {
            _id: post._id,
            isExported: res?.data?.isExported,
            isDeleted: res?.data?.isDeleted,
          },
        ]);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteFromDraft = async () => {
    try {
      API.delete(`/draft?id=${post._id}&email=${user.email}`, {
        headers: {
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      }).then(() => {
        const filterd = myDraftList.map((item) => {
          if (item._id === post._id) {
            return { ...item, isDeleted: true };
          } else {
            return item;
          }
        });
        setMyDraftList([...filterd]);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* {JSON.stringify(myDraftList)} */}
      {
        myDraftList?.some(
          (val) => val["_id"] === post._id && val["isDeleted"] === false
        ) ? (
          // myDraftList?.includes()
          <>
            <button onClick={() => deleteFromDraft()}>
              <SolidBookmarkIcon className="w-6 text-gray-400 hover:text-gray-700" />
            </button>
            {myDraftList?.some(
              (val) => val["_id"] === post._id && val?.isExported === true
            ) && (
              <CheckIcon className="w-6 text-gray-400 hover:text-gray-700" />
            )}
          </>
        ) : (
          <>
            <button onClick={() => addtoDrafte()}>
              <OutlineBookmarkIcon className="w-6 text-gray-400 hover:text-gray-700" />
            </button>
            {myDraftList?.some(
              (val) => val["_id"] === post._id && val?.isExported === true
            ) && (
              <CheckIcon className="w-6 text-gray-400 hover:text-gray-700" />
            )}
          </>
        )
        // )
      }
    </>
  );
}
