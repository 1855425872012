export const lastseenDateCount = (first) => {
    const now = new Date();
    const then = new Date(first);
    const difference = now.getTime() - then.getTime();

    if (difference <= 0) {
        return "Just now";
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const months = now.getMonth() - then.getMonth() + (12 * (now.getFullYear() - then.getFullYear()));
    const years = now.getFullYear() - then.getFullYear();

    if (days === 0) {
        return "Today";
    } else if (days === 1) {
        return "Yesterday";
    } else if (days < 30) {
        return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (months < 12) {
        return `${months} month${months > 1 ? 's' : ''} ago`;
    } else {
        return `${years} year${years > 1 ? 's' : ''} ago`;
    }
};




export const SeenDate = (a, b) => {
	let r = new Date(b).getTime() - new Date(a).getTime();
	if (r < 0) {
		return new Date(b).toLocaleDateString("en-CA", {
			year: "numeric",
			month: "short",
			day: "numeric",
		});
	} else {
		return new Date(a).toLocaleDateString("en-CA", {
			year: "numeric",
			month: "short",
			day: "numeric",
		});
	}
};

export const dateFormat = (i) => {
	let today = new Date().toLocaleDateString("en-CA").split("-");
	let date = i.split("-");
	let d = Number(today[2]) - Number(date[2]);
	if (today[0] === date[0] && today[1] === date[1]) {
		if (today[2] === date[2]) {
			return "Today";
		} else if (d <= 5) {
			if (d === 1) {
				return "Yesterday";
			} else {
				return d + " days ago";
			}
		} else {
			return i;
		}
	} else {
		return i;
	}
};

export const kFormatter = (num) => {
	return Math.abs(num) > 999
		? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
		: Math.sign(num) * Math.abs(num);
};